import React, { useEffect, useState } from "react";
import { Divider, Card, Badge, InputNumber, Button, Modal } from "antd";
import axios from "axios";

//import Graph from "./GraphOMG";
import Table from "./TableOMG";
import Wallet from "../Wallet/Wallet";

const GameCodeControllerPage = () => {
  const [Offers, setOffers] = useState([]);
  const [voucher, setVouchers] = useState([]);
  const [voucherToAdd, setVouchersToAdd] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sku, setSku] = useState("");
  const [voucherName, setVoucherName] = useState("");
  const [wallet, setWallet] = useState({});
  const [voucherPrice, setVoucherPrice] = useState(0);

  useEffect(() => {
    //  getOffers();
    getWallet();
    getVouchers();
  }, []);

  const getWallet = async () => {
    const body = {
      client: "gamecode",
    };
    await axios.post("/api/wallet/getwallet", body).then((res) => {
      setWallet(res.data);
    });
  };

  const getOffers = async () => {
    const offers = await axios.post("/api/gamecode/offers");

    let off = offers.data.offers;
    off.sort(function (a, b) {
      if (a.price.amount < b.price.amount) {
        return -1;
      }
      if (a.price.amount > b.price.amount) {
        return 1;
      }
      return 0;
    });
    setOffers(off);
  };

  function onChange(value) {
    console.log("changed", value);
    setVouchersToAdd(value);
  }

  function showModal(name, sku, price) {
    setSku(sku);
    setVoucherName(name);
    setVoucherPrice(price);
    setIsModalVisible(true);
  }

  const handleOk = async () => {
    //Purchase bulk vouchers
    const body = {
      sku: sku,
      qt: voucherToAdd,
    };
    const purchaseVouchers = await axios.post(
      "/api/gamecode/bullk/placeorder",
      body
    );
    //console.log("vouchers ", purchaseVouchers.data);
    setVouchersToAdd(1);
    await getVouchers();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getVouchers = async () => {
    const body = { stock: "all" };
    const vouchers = await axios.post("/api/gamecode/vouchers/status", body);
    setVouchers(vouchers.data.vouchers.response);
  };
  return (
    <div>
      <div style={{ maxWidth: "100%" }}>
        {/* <div style={{
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <Table plotName="OMG_INDEXES" />
                </div> */}

        <div>
          <Divider orientation="center">Game Code vouchers</Divider>

          {voucher.map((value, key) => (
            <div style={{ margin: "1rem" }}>
              <Card title={value.name} bordered={false}>
                <div>
                  quantity left : <b>{value.count}</b>
                  <Badge
                    dot={value.active}
                    status={value.active ? "success" : "error "}
                    size="small"
                  >
                    <span style={{ marginLeft: "8rem" }}>
                      <b>Product status </b>
                    </span>
                  </Badge>
                  <span style={{ marginLeft: "5rem" }}>
                    add vouchers from EZPIN :
                  </span>
                  <span style={{ marginLeft: "1rem" }}>
                    <InputNumber
                      min={1}
                      max={10}
                      defaultValue={1}
                      onChange={onChange}
                    />
                  </span>
                  <span>
                    <Button
                      type="primary"
                      onClick={() =>
                        showModal(value.name, value.sku, value.price)
                      }
                    >
                      Submit purchase
                    </Button>
                  </span>
                </div>
              </Card>
            </div>
          ))}

          <Wallet wallet={wallet} />
          <Modal
            title={" purchase"}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>
              Are you sure to purchase{" "}
              <b>
                {voucherToAdd * 5} x {voucherName}{" "}
              </b>{" "}
              ?
            </p>

            <p>
              Total price :<b> {voucherPrice * voucherToAdd * 5} </b> $
            </p>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default GameCodeControllerPage;
