import React from "react";
import { Tag } from "antd";
import logoSobflous from "../../../assets/images/logos/logo-sobflous.png";
import logoTT from "../../../assets/images/logos/tt.png";
export default function TableItem(props) {
  const variables = [
    { value: "N/A", color: "volcano" },

    { value: "payment error", color: "#f50" },
    { value: "otp error", color: "volcano" },
    { value: "FULFILLED", color: "#87d068" },
    { value: "REFUNDED", color: "green" },
    { value: "error payment", color: "#f50" },
    { value: "ERROR", color: "#f50" },
    { value: "expired", color: "volcano" },
    { value: "success", color: "#87d068" },
    { value: "paid", color: "#87d068" },
    { value: "REFUND", color: "#87d068" },
    { value: "sobflous", color: "#87d068" },
    { value: "clictopay", color: "#87d068" },
    { value: "pending", color: "#f50" },
  ];

  const res = variables.find((element) => {
    return element.value == props.value;
  });
  return (
    <div>
      {res ? (
        res.value == "sobflous" ? (
          <div>
            <img width="50px" src={logoSobflous} />
          </div>
        ) : res.value == "clictopay" ? (
          <div>
            <img width="50px" src={logoTT} />
          </div>
        ) : (
          <Tag color={res.color}>
            {res.value == "payment error" ? "No Balance" : res.value}
          </Tag>
        )
      ) : (
        <div>{props.value}</div>
      )}
    </div>
  );
}
