import React, { useContext, useEffect, useState } from "react";
import { Col, Divider, Row } from "antd";
import axios from "axios";
import GamesContext from "../../context/GamesContext";
import PrepaidBalance from "./PrepaidBalance";
import Card from "antd/es/card";
import Meta from "antd/es/card/Meta";
import { Table, Tag, Space } from "antd";

const PrepaidInformation = (props) => {
  const [balance, setBalance] = useState("0");
  const gamesContext = useContext(GamesContext);
  const [games, setGames] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [offersDetails, setOffersDetails] = useState([]);
  const [provider, setProvider] = useState("");
  const [codaproduct, setcodaproduct] = useState([]);
  const getBalance = () => {
    if (provider == "prepaidforge") {
      axios
        .post("/api/prepaidforge/balance")
        .then((res) => {
          setBalance(res.data.roundedFormattedString);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post("/api/ezpin/balance")
        .then((res) => {
          setBalance("$ " + res.data.balance);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getListSku = async () => {
    const list = await axios.post("/api/codapay/listsku");
    console.log("cooda list", list.data.body.result.skuList);
    setcodaproduct(list.data.body.result.skuList);
  };
  const columns = [
    {
      title: "Product",
      dataIndex: "description",
      key: "description",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Purchase Price",
      dataIndex: "price",
      key: "price",
      render: (price) => (
        <div style={{ fontWeight: "bold" }}>
          {price.amount} {price.currency}
        </div>
      ),
    },
    {
      title: "Stock",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity) => (
        <Tag color={quantity < 50 ? "#f50" : "#87d068"}>{quantity}</Tag>
      ),
    },
  ];

  useEffect(() => {
    if (provider == "codapayment") {
      getListSku();
    } else {
      getBalance();
    }
  }, [provider]);

  useEffect(() => {
    setProvider(props.match.params.provider);
  }, [props.match.params.provider]);

  // useEffect(() => {
  //   let prepaidGames = [];
  //   for (const index in gamesContext.games) {
  //     if (gamesContext.games[index].provider === "prepaidforge") {
  //       prepaidGames.push(gamesContext.games[index]);
  //     }
  //   }
  //   setGames(prepaidGames);

  //   setLoading(false);
  // }, [gamesContext.games]);

  async function getOffers(name) {
    const body = {
      name: name.toLowerCase(),
    };
    let skus = [];
    await axios.post("/api/offers/", body).then((offers) => {
      setSelectedOffers(offers.data);

      for (const offer of offers.data) {
        skus.push(offer.sku);
      }

      getOffersDetails(skus);
    });
  }

  async function getOffersDetails(skus) {
    const body = {
      sku: skus,
    };
    await axios.post("/api/prepaidforge/stock", body).then((response) => {
      setOffersDetails(response.data);
      console.log(response.data);
    });
  }
  function getTotalStockCount() {
    let stock = 0;

    for (const detail of offersDetails) {
      stock += detail.quantity;
    }

    return stock;
  }
  function onClick(gameName) {
    getOffers(gameName);
  }

  const balanceCom = () => {
    return <PrepaidBalance balance={balance} />;
  };

  return (
    <div>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Divider orientation="center">{provider} Balance</Divider>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {provider == "codapayment" ? null : balanceCom()}
        </div>

        <Divider orientation="center">{provider} Games</Divider>
        <div style={{ margin: "auto" }}>
          <Row gutter={16}>
            {!loading
              ? games.map((game) => (
                  <Col flex={3}>
                    <Card
                      onClick={() => {
                        onClick(game.Name);
                      }}
                      hoverable
                      style={{ width: 240 }}
                      cover={
                        <img
                          alt="example"
                          src={"https://boardgain.xyz" + game.Announcment_pic}
                        />
                      }
                    >
                      <Meta
                        title={game.Name}
                        description="Click to display information"
                      />
                    </Card>
                  </Col>
                ))
              : null}
          </Row>
        </div>
        <Divider orientation="center">
          Stock Left : {getTotalStockCount()}
        </Divider>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "50%" }}>
            <Table columns={columns} dataSource={codaproduct} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrepaidInformation;
