import React from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import "./auth.css";
import GalactechLogoWhite from "../../assets/images/logos/logo_galactech.png";
import { useHistory } from "react-router-dom";
import UserService from "../../services/userService";
import GalactechLogo from "../../assets/images/logos/blackversion.png";
import axios from "axios";
import ParticlesBg from "particles-bg";
import Background from "../../assets/images/background.jpg";
import Text from "antd/lib/typography/Text";
const key = "updatable";
const openMessage = () => {
  message.loading({ content: "Loading..", key });
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const Request = () => {
  const history = useHistory();
  // var error = "";
  const onFinish = (values) => {
    //openMessage("success");
    UserService.userRequest(values.username, values.email, values.password)
      .then((res) => {
        console.log("data", res);
        message
          .success({
            content: `Request Sent ${values.username}!`,
            key,
            duration: 2,
          })
          .then(() => {
            //window.location.reload();
          });
      })
      .catch((err) => {
        message.error(err.response.data.error);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message.error("This is an error message");
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F8F9F9",
        }}
      >
        <div style={{ width: "100%" }}>
          <div className="login-container">
            <div
              style={{
                padding: "12px",
                boxShadow: "0 0 100px rgb(0 0 0 / 8%)",
                width: "360px",
                height: "500px",
              }}
            >
              <div>
                <img src={GalactechLogo} alt="" width="300px" />
              </div>
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  {...tailLayout}
                  style={{ width: "100%" }}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    () => ({
                      validator(_, value) {
                        if (value.length >= 8) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error(
                              "Password should be at least 8 characters"
                            )
                          );
                        }
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                  {...tailLayout}
                  style={{ width: "100%" }}
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button block type="primary" htmlType="submit">
                    Send Request
                  </Button>
                  <Button
                    block
                    style={{ marginTop: "8px" }}
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "0",
              textAlign: "center",
              width: "100%",
              padding: "1rem",
            }}
          >
            <Text type="secondary">Galactech LLC Admin ©2020</Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default Request;
