import React, { useState } from "react";
import {
  Layout,
  Row,
  Col,
  Divider,
  Input,
  Skeleton,
  Result,
  Tag,
  message,
  Tooltip,
  Button,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { SmileOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
const { Content } = Layout;
const { Search } = Input;
export default function Refund() {
  const [loading, setLoading] = useState(false);
  const [purchase, setPurchase] = useState({});
  const [visible, setVisible] = useState(false);
  const onSearch = (value) => {
    if (value == "") {
      message.error("please insurt a valid ID");
    } else {
      setLoading(true);
      const body = {
        shortid: value,
      };
      axios.post("/api/gamecode/get/byshortid", body).then((res) => {
        if (res.data.message == "success") {
          setPurchase(res.data.purchase);
          //  console.log("purchase", res.data.purchase);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        } else {
          message.error("please input a valid id");
          setPurchase({});
          setTimeout(() => {
            setPurchase({});
            setLoading(false);
          }, 1500);
        }
      });
    }
  };

  const formatDate = (d) => {
    var date = new Date(d);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      "  " +
      strTime
    );
  };

  const openModel = () => {
    if (
      purchase[0].retailer_response.old_balance ==
      purchase[0].retailer_response.new_balance
    ) {
      message.info("You can't refund this order");
    } else {
      setVisible(true);
    }
  };

  const refund = async () => {
    if (
      purchase[0].purchase_info.status == "REFUNDED" ||
      purchase[0].purchase_info.status == "FULFILLED"
    ) {
      message.info("This order is completed");
    } else {
      // execute a route for handling everything
      const body = {
        sku: purchase[0].purchase_info.sku,
        price: purchase[0].purchase_info.price.original_amount,
        id: purchase[0]._id,
        enduser_amount: purchase[0].purchase_info.price.enduser_amount,
        short_id: purchase[0].short_id,
      };
      const refundres = await axios.post("/api/gamecode/lybiana/refund", body);
      console.log("ref", refundres.data);
      const bodyId = {
        shortid: purchase[0].short_id,
      };
      const purchaseupdated = await axios.post(
        "/api/gamecode/get/byshortid",
        bodyId
      );

      // console.log("ref",refundres.data);
      setVisible(false);
      setPurchase(purchaseupdated.data.purchase);
    }
  };
  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Content
        className="site-layout-background"
        style={{
          background: "#fff",
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Divider orientation="center">Refund</Divider>
        <b> Please insert the id of the purchase</b>
        <Row gutter={[24, 24]}>
          <Col span={4}>
            <Search placeholder="Purchase ID" onSearch={onSearch} enterButton />
          </Col>

          <Col span={16}>
            {Object.keys(purchase).length == 0 ? (
              <Result
                icon={<SmileOutlined />}
                title="From here you can refund any user!"
              />
            ) : loading ? (
              <Skeleton active />
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>Offer Name</th>
                    <th>Phone Number</th>

                    <th>Price</th>
                    <th>Payment Status</th>
                    <th>Status</th>
                    <th>Purchase Date</th>
                    <th>Old Balance</th>
                    <th>New Balance</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{purchase[0].purchase_info.description} </td>
                    <td>{purchase[0].user_info.phone_number}</td>
                    <td>
                      <b>{purchase[0].purchase_info.price.amount / 1000} LD</b>
                    </td>
                    <td>
                      {purchase[0].retailer_response.status == "FULFILLED" ? (
                        <Tag color="#87d068" key="success">
                          {purchase[0].retailer_response.status}
                        </Tag>
                      ) : purchase[0].retailer_response.status ==
                        "otp error" ? (
                        <Tag color="volcano">
                          {purchase[0].retailer_response.status} x{" "}
                          {purchase[0].retailer_response.attempt}
                        </Tag>
                      ) : purchase[0].retailer_response.status ==
                        "payment error" ? (
                        <Tag color="volcano">No balance</Tag>
                      ) : (
                        <Tag color="volcano">
                          {" "}
                          {purchase[0].retailer_response.status}
                        </Tag>
                      )}
                    </td>
                    <td>
                      {purchase[0].purchase_info.orderId != "N/A" ? (
                        <Tag color="#87d068" key="success">
                          {purchase[0].purchase_info.status}
                        </Tag>
                      ) : (
                        <Tag color="volcano">
                          {purchase[0].purchase_info.status}
                        </Tag>
                      )}
                    </td>
                    <td>{formatDate(purchase[0].creation_date)}</td>
                    <td>
                      <b>
                        {(
                          purchase[0].retailer_response.old_balance / 100000
                        ).toFixed(3)}{" "}
                        LD
                      </b>
                    </td>
                    <td>
                      {" "}
                      <b>
                        {" "}
                        {(
                          purchase[0].retailer_response.new_balance / 100000
                        ).toFixed(3)}{" "}
                        LD
                      </b>
                    </td>
                    <td>
                      <Tooltip title="Purchase Details">
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => {
                            // getPurchase();
                            openModel();
                          }}
                        >
                          {/* Details */}
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {/* {loading ? <Skeleton active /> : null} */}
          </Col>
        </Row>
      </Content>
      {Object.keys(purchase).length > 0 ? (
        <Modal
          width={500}
          title={`Order Details : ${purchase[0].short_id}`}
          visible={visible}
          onOk={() => {
            //setVisible(false);
            refund();
          }}
          onCancel={() => {
            setVisible(false);
          }}
          okText="Confirm"
          cancelText="Cancel"
        >
          <div style={{ textAlign: "center", fontSize: "15px" }}>
            Are you sure you want to refund order :
            <b> {purchase[0].short_id}</b> ?
          </div>
        </Modal>
      ) : null}
    </div>
  );
}
