import { Col, Row } from "antd";
import axios from "axios";
import React, { Component } from "react";
import TicketsTable from "./TicketsTable";

class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
    };
  }

  componentDidMount() {
    this.getTickets();
  }
  getTickets() {
    axios.get("/api/ticket/").then((res) => {
      this.setState({
        tickets: res.data,
      }, () => {console.log(this.state.tickets);});
    });
  }
  render() {
    return (
      <div className="site-layout-background" style={{ minHeight: 360 }}>
        <div className="header-secondary">Tickets</div>
        {/* <Row>
          <Col flex="3">
            <TicketsTable tickets={this.state.tickets} />
          </Col>
          <Col flex="2">Response</Col>
        </Row> */}
        <TicketsTable tickets={this.state.tickets} />
      </div>
    );
  }
}

export default Tickets;
