import React, { useEffect, useState, useContext } from "react";
import { Divider, Progress } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import UserContext from "../../context/UserContext";
import Axios from "axios";
const Wallet = (props) => {
  const [wallet, setWallet] = useState({});
  const userContext = useContext(UserContext);

  const getWallet = async () => {
    const body = {
      client: userContext.userData.user.market,
    };
    const walletResult = await Axios.post("/api/wallet/getwallet", body);

    setWallet(walletResult.data);
  };
  useEffect(() => {
    //   getWallet();
  }, []);

  // useEffect(() => {
  //   setWallet(props.wallet);
  // }, [props.wallet]);
  return (
    <>
      {wallet.balance != null ? (
        <div>
          <Divider orientation="center"> Digital Wallet</Divider>
          <div
            className="shadow-background"
            style={{
              backgroundColor: "#09327F",
              color: "white",
              padding: "1rem",
              borderRadius: "5px",
              minHeight: "120px",
            }}
          >
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: "0", right: "0" }}>
                <MoreOutlined />
              </div>
              <div style={{ fontSize: "16px", margin: "4px" }}>
                Active Balance:
              </div>
              <div style={{ fontSize: "20px", margin: "4px" }}>
                {wallet.balance.toFixed(2)}$
              </div>
              <div style={{ margin: "4px" }}>
                <Progress
                  percent={(
                    ((wallet.amount - wallet.balance) / wallet.amount) *
                    100
                  ).toFixed(2)}
                  strokeColor={"white"}
                  trailColor="#637DAF"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>loading...</div>
      )}
    </>
  );
};

export default Wallet;
