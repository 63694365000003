import { Card, Avatar } from "antd";

import img from "../../assets/images/img.png";

import Axios from "axios";
import React, { useEffect, useState } from "react";
const { Meta } = Card;
export default function LeaderboardItem(props) {
  const [avatar, setAvatar] = useState({});
  useEffect(() => {}, []);
  return (
    <Card
      bordered={false}
      hoverable
      style={{ width: 300 }}
      //   cover={<img style={{ height: "50%" }} alt="example" src={img} />}
      //   actions={[
      //     <ProfileOutlined key="profile" />,
      //     <EllipsisOutlined key="ellipsis" />,
      //   ]}
    >
      <Meta
        description={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              size="large"
              style={{ backgroundColor: props.color, color: "black" }}
            >
              {props.text}
            </Avatar>
            <div style={{ fontWeight: "bold" }}>
              {props.user.amountPaid + `TND`}
            </div>
            <div>
              {props.user.email.substr(0, props.user.email.indexOf("@"))}
            </div>
            <div>{props.user.email}</div>
          </div>
        }
      />
    </Card>
  );
}
