import React, { useState } from "react";
import { Modal, Popover } from "antd";
import PatchEditor from "./PatchEditor";
import {
  EditOutlined,
  EllipsisOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const PatchUpdater = (props) => {
  const [visible, setVisible] = useState(false);
  console.log(visible);
  return (
    <Popover content="Edit Patch Notes?" title="Edit">
      <EditOutlined key="edit" onClick={() => setVisible(true)} />
      <Modal
        title="New Patch Note"
        centered
        visible={visible}
        width={"90%"}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <PatchEditor setVisibility={setVisible} patch={props.patch} />
      </Modal>
    </Popover>
  );
};

export default PatchUpdater;
