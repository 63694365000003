import { Col, Divider, Spin, Table, Tag } from "antd";
import { List } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import Axios from "axios";
// import React, { useEffect, useState } from "react";
import PackDetailsItem from "./PackDetailsItem";
import PacksList from "./PacksList";

import React, { Component } from "react";

class PacksDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packs: [],
      details: [],
      loading: true,
    };
  }
  getPacks() {
    Axios.get("/api/packs/").then((res) => {
      this.setState({
        packs: res.data,
      });
      this.state.packs.forEach((pack) => {
        this.getDetails(pack.displayName, pack.name);
      });
    });
  }
  compare(a, b) {
    if (a.income < b.income) {
      return -1;
    }
    if (a.income > b.income) {
      return 1;
    }
    return 0;
  }

  getDetails(pack, sku) {
    Axios.get("/api/packs/pack-details/" + sku).then((res) => {
      this.state.details.push({
        displayName: pack,
        sku: sku,
        all: res.data.all,
        success: res.data.success,
        fail: res.data.fail,
        income: res.data.income,
      });
      this.state.details.sort(this.compare);

      if (this.state.packs.length === this.state.details.length) {
        this.setState({
          loading: false,
        });
      }
    });
  }
  componentDidMount() {
    this.getPacks();
  }
  render() {
    return (
      <div>
        <Divider orientation="center">Data</Divider>
        {!this.state.loading ? (
          <PacksList data={this.state.details} />
        ) : (
          <Spin
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            size="small"
          />
        )}
      </div>
    );
  }
}

export default PacksDetails;
