import { Table } from "antd";
import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import EditOffer from "./EditOffer";

const OffersList = (props) => {
    const columns = [
        {
            title: "Description",
            dataIndex: "description",
            key: 1,
        },
        {
            title: "Sku",
            dataIndex: "sku",
            key: 2,
        },
        {
            title: "Default Price",
            dataIndex: "price",
            key: 3,
            render: (price) => <div>{price.amount}</div>,
        },
        {
            title: "Promoted",
            dataIndex: "promotion",
            key: 4,
            responsive: ["md"],
            render: (promoted) => <div>
                {
                    promoted ? <div><CheckOutlined /></div> : <div>
                        <CloseOutlined />
                    </div>
                }
            </div>,

        },
        {
            title: "Promotion",
            dataIndex: "price",
            key: 3,
            render: (price) => <div>{price.promo}%</div>,
        },
        {
            title: 'Action',
            dataIndex: "description",
            key: 'operation',
            render: (text, record) => <EditOffer offer={record} />
        },


    ];
    return (
        <div>
            <Table columns={columns}
                dataSource={props.data} />
        </div>
    );
};

export default OffersList;
