import React, { Component } from "react";
import {
  Button,
  Col,
  Divider,
  Input,
  Skeleton,
  Space,
  Statistic,
  Table,
  Tag,
} from "antd";

import DetailsButton from "./DetailsButton";
import Highlighter from "react-highlight-words";

import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";

class SobflousTable extends Component {
  constructor(props) {
    super();
  }

  state = {
    searchText: "",
    searchedColumn: "",
  };

  formatDate(d) {
    var date = new Date(d);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      "  " +
      strTime
    );
  }

  formatDateWithoutPM(d) {
    var date = new Date(d);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Transaction ID",
        dataIndex: "transs_id",
        responsive: ["lg"],
      },
      {
        title: "Offer Name",
        dataIndex: "offer",
        filters: [
          {
            text: "UC 60",
            value: "Unknown Cash 60",
          },
          {
            text: "UC 325",
            value: "Unknown Cash 325",
          },
          {
            text: "UC 1800",
            value: "Unknown Cash 1800",
          },
          {
            text: "UC 660",
            value: "Unknown Cash 660",
          },
        ],
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value, record) => record.offer.indexOf(value) === 0,
      },
      {
        title: "Game",
        dataIndex: "game_name",
        defaultSortOrder: "descend",
        responsive: ["md"],
        filters: [
          {
            text: "PUBG Mobile",
            value: "pubg-mobile-voucher",
          },
          {
            text: "Free Fire",
            value: "free-fire",
          },
        ],
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value, record) => record.game_name.indexOf(value) === 0,
        // sorter: (a, b) => a.game_name - b.game_name,
      },
      {
        title: "Amount Paid",
        dataIndex: "amount",
        key: "amount",
        responsive: ["lg"],
        filterMultiple: false,
        onFilter: (value, record) => record.amount.indexOf(value) === 0,
        sorter: (a, b) => a.amount - b.amount,
        sortDirections: ["descend", "ascend"],
        render: (amount) => {
          return <div style={{ fontWeight: "bold" }}> {amount}TND</div>;
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        responsive: ["lg"],
        ...this.getColumnSearchProps("email"),
      },
      {
        title: "Status",
        dataIndex: "email",
        key: "email",
        defaultSortOrder: "descend",
        responsive: ["md"],
        filters: [
          {
            text: "Paid",
            value: "paid",
          },
          {
            text: "Error",
            value: "N/A",
          },
        ],
        onFilter: (value, record) => record.email.indexOf(value) === 0,
        render: (email) => (
          <>
            {email !== "N/A" ? (
              <Tag color="#87d068" key="success">
                PAID
              </Tag>
            ) : (
              <Tag color="#f50" key="error">
                INCOMPLETE
              </Tag>
            )}
          </>
        ),
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
        defaultSortOrder: "descend",
        responsive: ["md"],
        render: (message) => (
          <>
            {message !== "N/A" ? (
              <Tag color="#87d068" key="success">
                {message}
              </Tag>
            ) : (
              <Tag color="#f50" key="error">
                {message}
              </Tag>
            )}
          </>
        ),
      },
      {
        title: "Date",
        dataIndex: "creation_date",
        responsive: ["sm"],
        key: "creationDate",
        sorter: (a, b) => a.creation_date - b.creation_date,

        render: (creationDate) => {
          return <div>{this.formatDate(creationDate)}</div>;
        },
      },
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
        defaultSortOrder: "descend",
        responsive: ["md"],
        render: (orderId) => (
          <>
            {orderId === "OrderId" ? (
              <Tag color="volcano">No Order Submitted</Tag>
            ) : (
              <Tag color="purple" key="success">
                {orderId}
              </Tag>
            )}
          </>
        ),
      },
      {
        title: "Action",
        dataIndex: "orderId",
        key: "orderId",
        render: (orderId) => <DetailsButton order_id={orderId} />,
      },
    ];
    return (
      <div style={{ width: "100%", padding: "1rem" }}>
        <div style={{ textAlign: "right", marginBottom: "2%" }}>
          <CSVLink
            filename={
              `purchases_sobflous_` +
              this.formatDateWithoutPM("2020-10-30T14:10:38.484Z") +
              `-` +
              this.formatDateWithoutPM(this.props.purchases[0].creationDate) +
              `.csv`
            }
            data={this.props.purchases}
          >
            <Button type="primary" shape="round" icon={<DownloadOutlined />}>
              Download CSV
            </Button>
          </CSVLink>
        </div>
        <Table
          sticky
          bordered
          columns={columns}
          dataSource={this.props.purchases}
          // onChange={onChange}
        />
      </div>
    );
  }
}

export default SobflousTable;
