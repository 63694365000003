import React, { useEffect, useState } from "react";
import CenteredSelction from "./data/CenteredSelction";
import marketService from "../../services/marketService";
import { Col, Row, Select, DatePicker } from "antd";
const { Option } = Select;
const { RangePicker } = DatePicker;
export default function MarketFilter(props) {
  const [selectetdRetailer, setSelectetdRetailer] = useState("");
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getAllMarkets();
  }, []);
  useEffect(() => {
    setLoading(false);
  }, [markets]);

  const getAllMarkets = () => {
    const markets = ["orange", "lybiana", "mgp"];
    setMarkets(markets);
  };
  const handleDatePicker = (date, dateString) => {
    var newData = [];

    props.getByDate(date);
  };
  const handleChangeMarket = (value) => {
    const market = {
      market: value,
    };
    props.getByMarket(value);
  };
  const handleChangeRetailer = (value) => {
    const retailer = {
      retailer: value,
    };

    props.getByRetailer(retailer);
  };
  const listItems = () => {
    const optionsList = markets.map((item) => (
      <Option value={item}>{item}</Option>
    ));
    return optionsList;
  };
  const list = markets.map((item) => <Option value={item}>{item}</Option>);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col>
              {" "}
              <RangePicker onChange={handleDatePicker} />
            </Col>

            {loading ? null : (
              <Col flex="auto">
                <CenteredSelction
                  defaultValue="mgp"
                  title="Markets"
                  onChange={handleChangeMarket}
                  options={markets.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                />
              </Col>
            )}
            <Col flex="auto">
              <CenteredSelction
                defaultValue="All"
                title="Retailer"
                onChange={handleChangeRetailer}
                options={
                  <>
                    <Option value="all">All</Option>
                    <Option value="sobflous">Sobflous</Option>
                    <Option value="clictopay">Clictopay</Option>
                  </>
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
