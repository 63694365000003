import React from "react";
import { Select } from "antd";
const CenteredSelction = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ fontWeight: "bold", margin: 10 }}>{props.title}</div>
      <Select
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        style={{ width: 120 }}
        onChange={props.onChange}
      >
        {props.options}
      </Select>
    </div>
  );
};

export default CenteredSelction;
