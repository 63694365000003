import { Button } from "antd";
import React from "react";

const PurchasesHistoryItem = () => {
  return (
    <div style={{ width: "100%" }} style={{ padding: "6px" }}>
      <div
        className="box-shadow"
        style={{ display: "flex", flexDirection: "row", alignItems: "center" , padding:"8px"}}
      >
        <div style={{ margin: "auto" }}>icon</div>
        <div style={{ margin: "auto" }}>name</div>
        <div style={{ margin: "auto" }}>sku</div>
        <div style={{ margin: "auto" }}>quantity</div>
        <div style={{ margin: "auto" }}>
          <Button type="primary">Download Codes</Button>
        </div>
      </div>
    </div>
  );
};

export default PurchasesHistoryItem;
