import axios from "axios";

const service = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "/api/users"
      : `http://${window.location.hostname}:5000/api/users`,

  withCredentials: true,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  },
});

const errHandler = (err) => {
  //   if (err.response && err.response.data) {
  //     throw err;
  //   }
  throw err;
};

export default {
  service: service,

  getUserByUsername(username) {
    return service
      .get("/" + username)
      .then((res) => res.data)
      .catch(errHandler);
  },
  userRequest(username, email, password) {
    return service
      .post("/request", { username, email, password })
      .then((res) => res.data)
      .catch(errHandler);
  },
};
