import { Col, Row } from "antd";
import React from "react";
import LeaderboardItem from "./LeaderboardItem";

export default function TopUsers(props) {
  return (
    <div className="top-users-container">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col flex="auto">
          <LeaderboardItem user={props.second} color="#A2FC2B" text="2nd" />
        </Col>

        <Col flex="auto">
          {/* <div style={{ marginBottom: "2rem" }}> */}
          <LeaderboardItem user={props.first} color="#FDE200" text="1st" />
          {/* </div> */}
        </Col>
        <Col flex="auto">
          <LeaderboardItem user={props.third} color="#D5A4F5" text="3rd" />
        </Col>
      </Row>
    </div>
  );
}
