import React from "react";
import { Tag, message } from "antd";
import { Table } from "reactstrap";

import purchaseService from "../../services/purchaseService";
import { Modal, Button } from "antd";
import axios from "axios";
const PurchaseDetails = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isModalRefundVisible, setIsModalRefundVisible] = React.useState(false);
  const [cartItems,SetCartItems]=React.useState(props.order.cart_items)
  const [orderData,SetOrderData]=React.useState(props.order.order_data.items)
  const deliver = () => {
    const body = {
      orderId: props.order.payment_data.id,
      deliveryMethod: props.order.user_data.delivery_method,
    };
    purchaseService.Deliver(body).then((res) => {
      console.log("deliver");
      message.info("This purchase is Delivered ! ");
      setIsModalVisible(false);
    });
  };

  const Refund = () => {
    const body = {
      orderId: props.order.payment_data.id,
    };
    console.log("order",props.order.payment_data.id)
    axios.post("/api/mygameplace/refund", body).then((res) => {
      console.log("res", res.data.purchase);
      SetOrderData(res.data.purchase.order_data.items)
      if (res.data.message == "success") {
        message.info("This purchase is Refunded ! ");
      }
      setIsModalRefundVisible(false);
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showRefundModal = () => {
    setIsModalRefundVisible(true);
  };

  const handleOk = () => {};

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleRefundCancel = () => {
    setIsModalRefundVisible(false);
  };
  return (
    <div>
      <div className="card-description">
        <h4>Total Price</h4>
        <code>{props.order.payment_data.total_amount} TND</code>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Provider</th>
            <th>Sku</th>
            <th>Name</th>
            <th>Price (TND)</th>
            <th>Original Price</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item) => {
            return (
              <tr>
                <td>{item.provider}</td>
                <td>{item.sku}</td>
                <td>{item.name}</td>
                <td>
                  {item.price} x {item.quantity}
                </td>
                <td>{item.original_price}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <h4 className="card-title">Order Data</h4>
      <table className="table">
        <thead>
          <tr>
            <th>Ref Code</th>
            <th>Offers </th>
          </tr>
        </thead>
        <tbody>
          {orderData.map((item) => {
            return (
              <tr>
                <td>{item.reference_code}</td>
                <td>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Card Name</th>
                        <th>Card Code</th>
                        <th>Pin Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.cards.map((card) => {
                        return (
                          <tr>
                            <td>{card.item_name}</td>
                            <td>{card.voucher}</td>
                            <td>{card.pin_code}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ textAlign: "center" }}>
        <span>
          {" "}
          <Button type="primary" onClick={showModal}>
            Deliver
          </Button>
        </span>
        <span>
          {" "}
          <Button type="primary" onClick={showRefundModal}>
            Refund
          </Button>
        </span>
      </div>
      <div style={{ textAlign: "center" }}></div>

      <Modal
        title="Delivery confirmation"
        visible={isModalVisible}
        onOk={deliver}
        onCancel={handleCancel}
      >
        <p>
          Please confirm that this order will be sent to
          <b> {props.order.user_data.first_name}</b>
        </p>
        <table className="table">
          <tbody>
            {props.order.cart_items.map((item) => {
              return (
                <tr>
                  <td>{item.provider}</td>
                  <td>{item.sku}</td>
                  <td>{item.name}</td>
                  <td>
                    {item.price} x {item.quantity}
                  </td>
                  <td>{item.original_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <Button onClick={deliver}> Deliver </Button> */}
      </Modal>

      <Modal
        title="Refund confirmation"
        visible={isModalRefundVisible}
        onOk={Refund}
        onCancel={handleRefundCancel}
      >
        <p>
          Please confirm that this order will be sent to
          <b> {props.order.user_data.first_name}</b>
        </p>
        <table className="table">
          <tbody>
            {props.order.cart_items.map((item) => {
              return (
                <tr>
                  <td>{item.provider}</td>
                  <td>{item.sku}</td>
                  <td>{item.name}</td>
                  <td>
                    {item.price} x {item.quantity}
                  </td>
                  <td>{item.original_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <Button onClick={deliver}> Deliver </Button> */}
      </Modal>
    </div>
  );
};

export default PurchaseDetails;
