import React, { useContext } from "react";
import { Menu } from "antd";
import LinkButton from "../utils/LinkButton";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  AppstoreAddOutlined,
  ContainerOutlined,
  CustomerServiceOutlined,
  DatabaseOutlined,
  DollarOutlined,
  ForkOutlined,
  GroupOutlined,
  HistoryOutlined,
  HomeTwoTone,
  LogoutOutlined,
  TrophyOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import UserContext from "../../context/UserContext";

const openMessage = () => {
  localStorage.removeItem("token");
  window.location.reload();
};
const ClientSidebar = () => {
  const userContext = useContext(UserContext);

  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={["0"]}>
      <Menu.Item key="1" icon={<HomeTwoTone />}>
        <LinkButton link="/" buttonText="Dashboard" />
      </Menu.Item>
      {userContext.userData.user.market == "umbrella" ? (
        <Menu.Item key="2" icon={<HistoryOutlined />}>
          <LinkButton link="/shop" buttonText="Shop" />
        </Menu.Item>
      ) : null}

      {userContext.userData.user.marketsAccess.map((market, key) => (
        <Menu.Item key="3" icon={<HistoryOutlined />}>
          <LinkButton
            link={"/history/" + market}
            buttonText={"History " + market}
          />
        </Menu.Item>
      ))}

      <Menu.Item key="6" icon={<HistoryOutlined />}>
        <LinkButton link={"/refund/"} buttonText={"Refund"} />
      </Menu.Item>

      {/* <Menu.Item key="5" icon={<ForkOutlined />}>
        <LinkButton
          link="/transaction-history/orange"
          buttonText="Transactions"
        />
      </Menu.Item> */}
      {/* <Menu.Item key="8" icon={<UploadOutlined/>}>
                <LinkButton link="/games" buttonText="Games"/>
            </Menu.Item> */}
      {/* <Menu.Item key="12" icon={<ContainerOutlined />}>
        <LinkButton link="/tickets" buttonText="Tickets" />
      </Menu.Item> */}
      {/* <Menu.Item key="13" icon={<CustomerServiceOutlined />}>
        <LinkButton link="/clients" buttonText="Clients" />
      </Menu.Item> */}
      {/* <Menu.Item key="14" icon={<UserOutlined />}>
        <LinkButton link="/activity" buttonText="Active Users" />
      </Menu.Item> */}
      {/* <SubMenu key="15" icon={<GroupOutlined />} title="Packs">
        <Menu.Item key="15" icon={<DatabaseOutlined />}>
          <LinkButton link="/packs-data" buttonText="Packs Data" />
        </Menu.Item>
        <Menu.Item key="16" icon={<DollarOutlined />}>
          <LinkButton link="/packs-management" buttonText="Packs Management" />
        </Menu.Item>
      </SubMenu> */}
      <Menu.Item key="4" icon={<TrophyOutlined />}>
        <LinkButton link="/leaderboard" buttonText="Leaderboard" />
      </Menu.Item>
      <Menu.Item
        key="5"
        icon={<LogoutOutlined />}
        onClick={() => {
          openMessage();
        }}
      >
        <LinkButton link="/" buttonText="Logout" />
      </Menu.Item>
    </Menu>
  );
};

export default ClientSidebar;
