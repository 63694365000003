import React, {useEffect, useState} from 'react';
import {CSVLink} from "react-csv";
import {Button, message} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
const headers = [
    { label: "reference_code", key: "reference_code" },
    { label: "item_name", key: "item_name" },
    { label: "card_number", key: "card_number" },
    { label: "pin_code", key: "pin_code" },
];
const DownloadCsv = (props) => {
    const [loading,setLoading] = useState(true)
    const [dataToDownload,setDataToDownload] = useState([])

    const getPurchase = async () => {
        const body = {
            id: props.id,
        };

        const purchase = await axios.post("/api/purchase/get/single/umbrella", body);
        for (const item of purchase.data.order_data.items) {
            for (const card of item.cards) {
                dataToDownload.push({
                    reference_code:item.reference_code,
                    card_number:card.card_number,
                    pin_code:card.pin_code,
                    item_name:purchase.data.cart_item.name
                })
            }
        }
        console.log(dataToDownload)
        setLoading(false)
    };
    useEffect(() => {
        getPurchase()
    }, []);
    const success = () => {
        message.success("your file is downloading");
    };
    return (
        <div>
            {!loading ?<CSVLink
                filename={"umbrella" + ".csv"}
                data={dataToDownload}
                headers={headers}
                separator={";"}
            >
                <Button
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    icon={<DownloadOutlined/>}
                    onClick={success}
                >
                    Download CSV
                </Button>
            </CSVLink>:null}
        </div>
    );
};

export default DownloadCsv;