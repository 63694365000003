import React from 'react';
import {Button, Result} from "antd";
const UnauthorizedPage = () => {
    const logout = () => {
        localStorage.removeItem("token");
        window.location.reload();
    };
    return (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this pag yet."
            extra={<Button onClick={()=>{logout()}} type="primary">Logout</Button>}
        />
    );
};

export default UnauthorizedPage;
