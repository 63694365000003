import React, { Component } from 'react';
import { Card } from 'antd';
import { Skeleton } from 'antd';
class CardSkeleton extends Component {
    render() {
        return (
        <Card
            hoverable
            style={{ width: 240 }}
            cover={<Skeleton.Image style={{width:"500%"}} />}
        >
            <Skeleton/>
        </Card>
        );
    }
}

export default CardSkeleton;
