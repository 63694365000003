import React, { Component, useContext } from "react";

import TableMAT from "../gdash/TableMGP";
import TableMGParr from "../gdash/TableMGParr";
//import Graph from "../gdash/GraphOMG";
import Table from "../gdash/TableOMG";
import GeneralCards from "../utils/data/GeneralCards";
import UserContext from "../../context/UserContext";
const Dashboard = (props) => {
  const userContext = useContext(UserContext);
  return (
    <div>
      <GeneralCards user={userContext.userData.user} />
    </div>
  );
};

export default Dashboard;
