import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import topnetLogo from "../../assets/images/logos/logo-topnet-2.png";

const WelcomeName = () => {
  const userContext = useContext(UserContext);

  return (
    <div style={{ color: "#001529", fontSize: "18px" }}>
      Hi{" "}
      <span style={{ fontWeight: "bold" }}>
        {userContext.userData.user.username}!
      </span>
      {userContext.userData.user.market == "topnet" ? (
        <span style={{}}>
          <img src={topnetLogo} width={100} />
        </span>
      ) : null}
    </div>
  );
};

export default WelcomeName;
