import React, { Component } from "react";
import PatchCreator from "./PatchCreator";
import { Divider, Spin, Tag, Popover } from "antd";
import axios from "axios";
import { List, Card, notification } from "antd";
import ReactHtmlParser from "react-html-parser";
import {
  EditOutlined,
  EllipsisOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import PatchUpdater from "./PatchUpdater";

class Patches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patches: [],
      selectedPatch: {},
      loading: true,

      updateVisible: false,
    };
  }
  openRemovedNotification = (type) => {
    notification[type]({
      message: "Patch Note Removed",
      description: "You have successfully Removed a patch note.",
    });
  };
  componentDidMount() {
    this.getAllPatches();
  }
  getAllPatches() {
    axios
      .post("/api/patch-notes/", { app: this.props.app })
      .then((res) => {
        this.setState({
          patches: res.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  formatDate(d) {
    var date = new Date(d);
    //var hours = date.getHours();
    //var minutes = date.getMinutes();
    //var ampm = hours >= 12 ? "pm" : "am";
    //hours = hours % 12;
    //hours = hours ? hours : 12;
    //minutes = minutes < 10 ? "0" + minutes : minutes;
    //var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear() // "  " +
      // strTime
    );
  }

  getPatch = (id) => {
    console.log(id);
    axios
      .get("/api/patch-notes/get/" + id)
      .then((res) => {
        this.setState({
          selectedPatch: res.data,
          updateVisible: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    return (
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Divider orientation="center">Recent Patch Notes </Divider>
        <PatchCreator refresh={this.getAllPatches()} app={this.props.app} />

        {this.state.loading ? (
          <Spin
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "2%",
            }}
            size="small"
          />
        ) : (
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 6,
              xxl: 3,
            }}
            dataSource={this.state.patches}
            renderItem={(item) => (
              <List.Item>
                <Card
                  extra={
                    <div>
                      <Tag>{this.formatDate(item.date)}</Tag>
                      {item.type === "major" ? (
                        <Tag color="#f50">{item.type}</Tag>
                      ) : item.type === "minor" ? (
                        <Tag color="blue">{item.type}</Tag>
                      ) : item.type === "major" ? (
                        <Tag color="red">{item.type}</Tag>
                      ) : (
                        <Tag color="green">{item.type}</Tag>
                      )}
                    </div>
                  }
                  title={item.title}
                  actions={[
                    <PatchUpdater patch={item} />,
                    <Popover content="Remove Patch Note" title="Delete">
                      <DeleteOutlined
                        key={item.title + "-delete"}
                        onClick={() => {
                          axios
                            .delete("/api/patch-notes/delete/" + item._id)
                            .then((res) => {
                              this.getAllPatches();
                              this.openRemovedNotification("error");
                              //console.log(this.state.patches.indexOf(item));
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      />
                    </Popover>,

                    <Popover content="More options" title="More">
                      <EllipsisOutlined key="ellipsis" />
                    </Popover>,
                  ]}
                >
                  {ReactHtmlParser(item.content)}
                </Card>
              </List.Item>
            )}
          />
        )}
      </div>
    );
  }
}

export default Patches;
