import { Table, Tooltip } from "antd";
import React from "react";
import { StarTwoTone, DollarCircleTwoTone } from "@ant-design/icons";

const PacksList = (props) => {
  const columns = [
    {
      title: "Pack Name",
      dataIndex: "displayName",
      key: "displayName",
      render: (text) => (
        <a>
          {text === "Unknown Cash 60" ? (
            <Tooltip title="Most Purchases" color="#DC9202" key="#DC9202">
              <StarTwoTone style={{ margin: "5px" }} twoToneColor="#DC9202" />
            </Tooltip>
          ) : null}
          {text === "Unknown Cash 325" ? (
            <Tooltip title="Most Revenu" color="#eb2f96" key="#eb2f96">
              <DollarCircleTwoTone
                style={{ margin: "5px" }}
                twoToneColor="#eb2f96"
              />
            </Tooltip>
          ) : null}
          {text}
        </a>
      ),
    },
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Income",
      dataIndex: "income",
      key: "income",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.income - b.income,
      render: (text) => <div>{Math.round(text)} TND</div>,
    },
    {
      title: "Total Purchases",
      dataIndex: "all",
      key: "all",
      responsive: ["md"],
      defaultSortOrder: "descend",
      sorter: (a, b) => a.all - b.all,
    },
    {
      title: "Success Purchases",
      dataIndex: "success",
      key: "success",
      responsive: ["lg"],
      defaultSortOrder: "descend",
      sorter: (a, b) => a.success - b.success,
    },
    {
      title: "Failed Purchases",
      dataIndex: "fail",
      key: "fail",
      responsive: ["lg"],
      defaultSortOrder: "descend",
      sorter: (a, b) => a.fail - b.fail,
    },
  ];
  return (
    <div>
      <Table columns={columns} dataSource={props.data} />
    </div>
  );
};

export default PacksList;
