import React, { useContext } from "react";
import { Button, Divider, Result } from "antd";
import OffersDetails from "./OffersDetails";
import OfferCreation from "./OfferCreation";
import image404 from "../../assets/images/404-error-not-found.png";
import UserContext from "../../context/UserContext";
const PacksManagement = () => {
  const userContext = useContext(UserContext);
  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      {userContext.userData.user.role === "client" ? (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited still does not exist."
          extra={<Button type="primary">Back Home</Button>}
        />
      ) : (
        <div>
          <Divider orientation="center">Packs Management</Divider>
          <div>
            <OfferCreation />
          </div>
          <div>
            <OffersDetails gameName="pubg-mobile-voucher" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PacksManagement;
