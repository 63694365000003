import React from 'react';
import StatisticsCard from "../utils/data/StatisticsCard";

const PrepaidBalance = (props) => {
    return (
        <StatisticsCard
            title={"Balance"}
            value={props.balance}
        />
    );
};

export default PrepaidBalance;
