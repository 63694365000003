import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import history from "../../history";
class LinkButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Link
        to={this.props.link}
        // onClick={() => {
        //   history.push(this.props.link);
        // }}
      >
        {this.props.buttonText}
      </Link>
    );
  }
}

export default withRouter(LinkButton);
