import React, { Component } from 'react';
import { Tag } from 'antd';

class GamePurchaseItem extends Component {
    render() {
        return (
            <div>
                <Tag color="#2db7f5">{this.props.purchase.platform}</Tag>
                {
                    this.props.purchase.status === "paid" 
                    ? 
                    <Tag color="#87d068">Paid</Tag> 
                    :
                    <Tag color="#f50">Error</Tag>
                }
                <Tag color="#108ee9">{this.props.purchase.amount} TND</Tag>
            </div>
        );
    }
}

export default GamePurchaseItem;
