import React, { Component } from 'react';
import { Card } from 'antd';
const { Meta } = Card;
class CardGame extends Component {
    render() {
        return (
        <Card
            hoverable
            style={{ width: 240 }}
            cover={<img alt={this.props.game.Name} src={"https://boardgain.xyz" + this.props.game.Logo} width="100%" />}
        >
            <Meta title={this.props.game.Name} description={this.props.game.Studio} />
        </Card>
        );
    }
}

export default CardGame;
