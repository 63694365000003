import React, { useEffect, useState } from "react";
import { Button, Divider, Input, Select } from "antd";

import { EyeOutlined, UngroupOutlined } from "@ant-design/icons";
import axios from "axios";

const { Option } = Select;
const PacksCreation = () => {
    const [games, setGames] = useState([]);
    const [gameName, setGameName] = useState("");
    const [description, setDescription] = useState("");
    const [skuName, setSkuName] = useState("");
    const [loading, setLoading] = useState(true);
    const [amount, setAmount] = useState(0);

    const getGamesList = () => {
        axios.get("/api/games").then((res) => {
            setGames(res.data);
            setLoading(false);
        });
    };
    useEffect(() => {
        getGamesList();
    }, []);

    const onChange = (value) => {
        setGameName(value);
    };
    const createPack = () => {
        let body = {
            game_name: gameName,
            description: description,
            sku: skuName,
            price: {
                amount: Number(amount),
            }
        };
        // axios.post("/api/offers/create", body).then((res) => {
        //     console.log(res);
        // });
    };
    return (
        <div>
            <Divider orientation="center">New Pack</Divider>
            {!loading ? (
                <Select
                    style={{ marginBottom: "15px" }}
                    showSearch
                    placeholder="Select a game"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {games.map((game) => {
                        return <Option
                            value={game.Name === "PUBG Mobile" ? "pubg-mobile-voucher" : game.Name}>{game.Name}</Option>;
                    })}
                    {/* <Option value={games[0].Name}>{games[0].Name}</Option> */}
                </Select>
            ) : null}

            <Input
                style={{ marginBottom: "15px" }}
                name="sku"
                size="default"
                onChange={(e) => setSkuName(e.target.value)}
                placeholder="Sku Name"
                prefix={<UngroupOutlined />}
            />

            <Input
                style={{ marginBottom: "15px" }}
                name="description"
                size="default"
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                prefix={<UngroupOutlined />}
            />
            <Input
                style={{ marginBottom: "15px" }}
                name="amount"
                size="default"
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Price"
                type="Number"
                prefix={<EyeOutlined />}
            />

            <Button
                // style={{margin: "1rem"}}
                type="primary"
                block
                onClick={createPack}
            >
                Create
            </Button>
        </div>
    );
};

export default PacksCreation;
