import React from "react";
import { Result, Button, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Paragraph, Text } = Typography;
const ErrorResult = () => {
   const history = useHistory();

   return (
      <div style={{ paddingTop: "5%" }}>
         <Result
            status="error"
            title="Submission Failed"
            subTitle="Please check the following information before resubmitting."
            extra={[
               <Button
                  type="primary"
                  key="buy"
                  onClick={() => {
                     localStorage.removeItem("token");
                     window.location.reload();
                  }}
               >
                  Home
               </Button>,
            ]}
         >
            <div className="desc">
               <Paragraph>
                  <Text
                     strong
                     style={{
                        fontSize: 16,
                     }}
                  >
                     Your account is either:
                  </Text>
               </Paragraph>
               <Paragraph>
                  <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                  Disabled by one of our administrator.
               </Paragraph>
               <Paragraph>
                  <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                  Still waiting approval.
               </Paragraph>
               <Text
                  strong
                  style={{
                     fontSize: 16,
                  }}
               >
                  Please make sure to contact one of our administrators.
               </Text>
            </div>
         </Result>
      </div>
   );
};

export default ErrorResult;
