import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./Login";
import Request from "./Request";

export default function Auth() {
   return (
      <div>
         <BrowserRouter>
            <Switch>
               <Route exact path="/" component={Login} />
               <Route exact path="/request" component={Request} />
            </Switch>
         </BrowserRouter>
      </div>
   );
}
