import { Button, Col, Result, Row } from "antd";
import React, { useContext } from "react";
import GamesHorizontalList from "./GamesHorizontalList";
import PacksCreation from "./PacksCreation";
import PacksDetails from "./PacksDetails";
import UserContext from "../../context/UserContext";
import image404 from "../../assets/images/404-error-not-found.png";
const PacksData = () => {
  const userContext = useContext(UserContext);

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      {userContext.userData.user.role === "client" ? (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited still does not exist."
          extra={<Button type="primary">Back Home</Button>}
        />
      ) : (
        <Row>
          {/* <Col span={15}>
      <GamesHorizontalList />
    </Col> */}
          <Col span={16}>
            <PacksDetails game="PUBG Mobile" />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PacksData;
