import React, { useEffect, useState } from "react";
import { Modal, Button, Space, Spin, message } from "antd";

//import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import {
  ArrowUpOutlined,
  CheckCircleOutlined,
  DollarOutlined,
  DollarTwoTone,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  DownloadOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
const PurchaseConfirm = (props) => {
  const { confirm } = Modal;
  const antIcon = <LoadingOutlined spin />;
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const fetchData = async () => {
    const response = await axios.post("/api/products/single", {
      sku: props.sku,
    });

    setData(response.data);
    setFetching(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const Purchase = async () => {
    //console.log("purchase", body);

    // if (avaibility.data.availability) {
    console.log("purside to purchase");

    const ezpinbody = {
      total_amount: data.price.price * props.quantity,
      sku: parseInt(props.sku),
      quantity: props.quantity,
      price: data.price.original_price,
      email: "yessine@galactechstudio.com",
      pre_order: 0,
      store_id: 1234,
    };
    const placeOrder = await axios.post(
      "/api/ezpin/stagingplaceorder",
      ezpinbody
    );

    const purchaseBody = {
      market: "umbrella",
      user_data: {
        user_name: props.user.user_name,
        email: props.user.email,
        client: props.user.role,
      },
      payment_data: {
        status: "success",
        total_amount: data.price.price * props.quantity,
        quantity: placeOrder.data.details.count,
      },
      provider_data: {
        status: "success",
        name: "ezpin",
      },
      order_data: {
        items: [
          {
            reference_code: placeOrder.data.placeOrder.reference_code,
            cards: placeOrder.data.details.results,
          },
        ],
      },
      cart_item: data,
    };
    const purchase = await axios.post("/api/purchase/create", purchaseBody);
    console.log("purchase is done", purchase.data);
    message.success("your file is downloading");
    // } else {
    //   message.success("fail");
    // }
  };
  async function showConfirm() {
    const body = {
      price: data.price.original_price,
      count: props.quantity,
      sku: props.sku,
    };
    const avaibility = await axios.post("/api/ezpin/catalogavailability", body);
    console.log("availibilty", avaibility.data);

    confirm({
      title: `Confirm your purchase of ` + data.name,
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          {fetching ? (
            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spin indicator={antIcon} />
                <div>Fetching data</div>
              </div>
            </div>
          ) : (
            <div>
              <p>You are about to purchase:</p>
              <p>
                {data.name} x {props.quantity}
              </p>
              {console.log("data", props.quantity)}
              <p>Total Price: {data.price.price * props.quantity} USD</p>
            </div>
          )}
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            icon={<DownloadOutlined />}
            onClick={Purchase}
          >
            Purchase
          </Button>
        </div>
      ),
      // onOk() {
      //   Purchase();
      // },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  return (
    <Space>
      <Button type="primary" onClick={showConfirm}>
        Purchase
      </Button>
    </Space>
  );
};

export default PurchaseConfirm;
