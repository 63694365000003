import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";

const EditOffer = (props) => {
  const [editDialog, setEditDialog] = useState(false);
  const [gameName, setGameName] = useState(props.offer.game_name);
  const [sku, setSku] = useState(props.offer.sku);
  const [description, setDescription] = useState(props.offer.description);
  const [defaultPrice, setDefaultPrice] = useState(props.offer.price.amount);
  const [promo, setPromo] = useState(props.offer.price.promo);
  const [promotionBool, setPromotion] = useState(props.offer.promotion);
  const [currency, setCurrency] = useState(props.offer.price.currency);

  const showEditModal = () => {
    setEditDialog(true);
  };

  const handleSubmit = () => {
    const body = {
      game_name: gameName,
      description: description,
      sku: sku,
      price: {
        amount: defaultPrice,
        currency: currency,
        promo: promo,
      },
      promotion: promotionBool,
    };

    axios
      .post("/api/offers/update", body)
      .then((res) => {
        // console.log(res.data)
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const deleteOffer = () => {
    axios.delete("/api/offers/delete/" + props.offer.sku).then((res) => {
      window.location.reload();
    });
  };
  return (
    <div>
      <Button
        style={{
          margin: "5px",
          backgroundColor: "#00C24E",
          color: "white",
          border: "none",
        }}
        shape={"round"}
        onClick={() => {
          showEditModal();
        }}
      >
        Edit
      </Button>
      <Button
        style={{
          margin: "5px",
          backgroundColor: "#FF4444",
          color: "white",
          border: "none",
        }}
        shape={"circle"}
        icon={<DeleteOutlined />}
        onClick={() => deleteOffer()}
      />
      <Modal
        title={
          <div style={{ color: "white" }}>Edit offer {props.offer.sku}</div>
        }
        centered
        visible={editDialog}
        onOk={() => handleSubmit()}
        onCancel={() => setEditDialog(false)}
      >
        <Form.Item label="Game Name">
          <Input
            placeholder={props.offer.game_name}
            disabled
            onChange={(e) => setGameName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input
            placeholder={props.offer.description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Default Price">
          <Input
            placeholder={props.offer.price.amount}
            onChange={(e) => setDefaultPrice(Number(e.target.value))}
          />
        </Form.Item>
        <Form.Item label="Currency">
          <Input
            placeholder={props.offer.price.currency}
            onChange={(e) => setCurrency(Number(e.target.value))}
          />
        </Form.Item>
        <Form.Item label="Promo Percentage">
          <Input
            placeholder={props.offer.price.promo}
            onChange={(e) => setPromo(Number(e.target.value))}
          />
        </Form.Item>
        <Form.Item label="Promoted">
          <Checkbox
            checked={promotionBool}
            onChange={(e) => setPromotion(!promotionBool)}
          />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default EditOffer;
