import { Card, Col, DatePicker, Row } from 'antd';
import React, { useEffect, useState } from 'react';

const InvoiceManagement = () => {

    const [purchasesCount, setPurchasesCount] = useState(0);
    const [income, setIncome] = useState(0);
    const [selectedDate, setSelectedDate] = useState({});
    function onChange(date, dateString) {
        setSelectedDate(dateString)
    }
    useEffect(() => {

    }, []);
    return (
        <div className="site-layout-background" style={{ minHeight: "100%" }}>
            <div className="header-secondary">Invoice</div>
            <div>
                <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                    Select Month <DatePicker onChange={onChange} picker="month" />
                </div>
                <div style={{ backgroundColor: "#F0F2F5", width: "100%" }}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col flex="auto">
                            <Card title="Purchases" bordered={false} style={{ width: '100%' }}>
                                <div>
                                    52,100
                                </div>
                            </Card>
                        </Col>
                        <Col flex="auto">
                            <Card title="Income (Without Promotions)" bordered={false} style={{ width: '100%' }}>
                                <div>
                                    17.150 TND
                                </div>
                            </Card>
                        </Col>
                        <Col flex="auto">
                            <Card title="Income (With Promotions)" bordered={false} style={{ width: '100%' }}>
                                <div>
                                    17.150 TND
                                </div>
                            </Card>
                        </Col>
                        <Col flex="auto">
                            <Card title="Income (With Promotions)" bordered={false} style={{ width: '100%' }}>
                                <div>
                                    17.150 TND
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                    Select Month <DatePicker onChange={onChange} picker="month" />
                </div>
            </div>
        </div >

    );
}

export default InvoiceManagement;
