import React, { Component } from "react";
import { Button, Tooltip } from "antd";
import Modal from "antd/lib/modal/Modal";
import { InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import PurchaseDetails from "../purchases/PurchaseDetails";
class DetailsButton extends Component {
  constructor(props) {
    super();
    this.state = {
      visible: false,
      order: null,
    };
  }

  componentDidMount() {}

  async getPurchaseDetails() {
    console.log("Getting order information");
    const token = await axios.post("/api/mygameplace/generateToken");
    console.log(token);
    const body = {
      orderId: this.props.orderId,
      token: token.data.token,
    };
    console.log("order id", body);
    await axios
      .post("/api/purchase/orderid/", body)
      .then((res) => {
        this.setState({
          order: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    return (
      <div style={{ width: "100%", margin: "3px" }}>
        <Tooltip title="Purchase Details">
          <Button
            // style={{ width: "100%" }}
            type="primary"
            shape="circle"
            icon={<InfoCircleOutlined />}
            onClick={() => {
              this.getPurchaseDetails();
              this.setState({
                visible: true,
              });
            }}
          >
            {/* Details */}
          </Button>
        </Tooltip>

        <Modal
          width={1000}
          title={`Order Details : ${this.props.orderId}`}
          visible={this.state.visible}
          onOk={() => {
            this.setState({
              visible: false,
            });
          }}
          onCancel={() => {
            this.setState({
              visible: false,
            });
          }}
          okText="Ok"
          cancelText="Cancel"
        >
          {this.state.order !== null ? (
            <PurchaseDetails error={false} order={this.state.order} />
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default DetailsButton;
