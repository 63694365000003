import { Button, Popconfirm, Spin, Tooltip } from "antd";
import React, { Component } from "react";
import {
  DeleteFilled,
  DownloadOutlined,
  CheckOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { withRouter } from "react-router-dom";
class TicketsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket: {},
      loading: true,
    };
  }

  componentDidMount() {
    axios
      .get("/api/ticket/single/" + this.props._id)
      .then((res) => {
        this.setState({ ticket: res.data, loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  removeTicket = () => {
    axios
      .delete("/api/ticket/remove/" + this.props._id)
      .then((res) => {
        axios.get("/api/ticket/").then((res) => {
          window.location.reload();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  resolveTicket = () => {
    axios
      .post("/api/ticket/resolve/" + this.props._id)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    return (
      <div>
        {!this.state.loading ? (
          <div>
            {" "}
            {this.state.ticket.ticketStatus !== "resolved" ? (
              <Popconfirm
                title="Confirm you resolved this ticket?"
                onConfirm={() => {
                  this.resolveTicket();
                }}
                onCancel={false}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip placement="bottomLeft" title={"Mark as resolved"}>
                  <Button
                    style={{
                      margin: "3px",
                      backgroundColor: "#59B259",
                      borderColor: "#59B259",
                    }}
                    type="primary"
                    shape="round"
                    icon={<CheckOutlined />}
                    size={"small"}
                  >
                    Resolved
                  </Button>
                </Tooltip>
              </Popconfirm>
            ) : null}
            <Popconfirm
              title="Are you sure remove this ticket?"
              onConfirm={() => this.removeTicket()}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="bottomLeft" title={"Remove Ticket"}>
                <Button
                  style={{ margin: "3px" }}
                  type="primary"
                  danger
                  shape="round"
                  icon={<DeleteFilled />}
                  size={"small"}
                >
                  Remove
                </Button>
              </Tooltip>
            </Popconfirm>
          </div>
        ) : (
          <Spin />
        )}
      </div>
    );
  }
}

export default withRouter(TicketsManagement);
