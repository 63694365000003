import React from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import "./auth.css";
import GalactechLogoWhite from "../../assets/images/logos/logo_galactech.png";

import GalactechLogo from "../../assets/images/logos/blackversion.png";
import axios from "axios";
import ParticlesBg from "particles-bg";
import Background from "../../assets/images/background.jpg";
import Text from "antd/lib/typography/Text";
import { useHistory } from "react-router-dom";
const key = "updatable";
const openMessage = () => {
  message.loading({ content: "Loading..", key });
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};
const Login = () => {
  const history = useHistory();
  // var error = "";
  const onFinish = (values) => {
    openMessage("success");
    axios
      .post("/api/users/signin", values)
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          message
            .success({
              content: `Welcome back ${res.data.user.username}!`,
              key,
              duration: 2,
            })
            .then(() => {
              window.location.reload();
            });
        } else {
          message.error({
            content: "Verify your informations",
            key,
            duration: 2,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          console.log(err.response.data.message);
          // error = err.response.data.message;
        }
      });
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F8F9F9",
        }}
      >
        <div style={{ width: "100%" }}>
          <div className="login-container">
            <div
              style={{
                padding: "12px",
                boxShadow: "0 0 100px rgb(0 0 0 / 8%)",
                width: "360px",
                height: "360px",
              }}
            >
              <div>
                <img src={GalactechLogo} alt="" width="300px" />
              </div>
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  {...tailLayout}
                  style={{ width: "100%" }}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button block type="primary" htmlType="submit">
                    Login
                  </Button>
                  <Button
                    block
                    style={{ marginTop: "8px" }}
                    onClick={() => {
                      history.push("/request");
                    }}
                  >
                    Request an account
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "0",
              textAlign: "center",
              width: "100%",
              padding: "1rem",
            }}
          >
            <Text type="secondary">Galactech LLC Admin ©2020</Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
