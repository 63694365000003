import { Badge, Divider, Tag } from "antd";
import { List, Avatar } from "antd";
import { map } from "async";
import React, { useEffect, useState, useContext } from "react";
import io from "socket.io-client";

import UserContext from "../../context/UserContext";

const ActiveUsers = () => {
  const [users, setUsers] = useState([]);
  const userContext = useContext(UserContext);
  // useEffect(() => {
  //    const ENDPOINT = "localhost:5000";
  //    var socket = io(ENDPOINT);
  //    socket.on("getJoined", (users) => {
  //       console.log(users);
  //       setUsers(users);
  //    });
  // }, []);

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Divider orientation="center">Active Administrators</Divider>
      <List
        itemLayout="horizontal"
        dataSource={users}
        renderItem={(user) => (
          <List.Item>
            {userContext.userData.user.username ===
            user.userData.user.username ? (
              <List.Item.Meta
                style={{ backgroundColor: "#F0F2F5", padding: "10px" }}
                avatar={
                  <Badge color="green" count={5} offset={[5, 20]}>
                    <Avatar
                      style={{
                        backgroundColor: "#1890FF",
                        verticalAlign: "center",
                      }}
                      size="large"
                      // gap={gap}
                    >
                      {user.userData.user.username.charAt(0)}
                    </Avatar>
                  </Badge>
                }
                title={<a href="/">{user.userData.user.username}</a>}
                description={
                  <div>
                    {user.connectionDate}{" "}
                    <Tag
                      color={
                        user.userData.user.role === "super" ? "#f50" : "#108ee9"
                      }
                    >
                      {user.userData.user.role.toUpperCase()}
                    </Tag>
                  </div>
                }
              />
            ) : (
              <List.Item.Meta
                style={{ padding: "10px" }}
                avatar={
                  <Badge color="green" count={5} offset={[5, 20]}>
                    <Avatar
                      style={{
                        backgroundColor: "#1890FF",
                        verticalAlign: "center",
                      }}
                      size="large"
                      // gap={gap}
                    >
                      {user.userData.user.username.charAt(0)}
                    </Avatar>
                  </Badge>
                }
                title={<a href="/">{user.userData.user.username}</a>}
                description={
                  <div>
                    {user.connectionDate}{" "}
                    <Tag
                      color={
                        user.userData.user.role === "super" ? "#f50" : "#108ee9"
                      }
                    >
                      {user.userData.user.role.toUpperCase()}
                    </Tag>
                  </div>
                }
              />
            )}
          </List.Item>
        )}
      />
    </div>
  );
};

export default ActiveUsers;
