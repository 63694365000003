import { List, Result, Avatar, Spin } from "antd";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";

export default class MessagesScrollList extends Component {
  state = {
    data: [],
    loading: false,
    hasMore: true,
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={this.handleInfiniteOnLoad}
          hasMore={!this.state.loading && this.state.hasMore}
          useWindow={false}
        >
          <List
            dataSource={this.state.data}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  }
                  title={<a href="https://ant.design">{item.name.last}</a>}
                  description={item.email}
                />
                <div>Content</div>
              </List.Item>
            )}
          >
            <Result
              status="404"
              title="404"
              subTitle="Instant Messaging Is WIP."
            />
          </List>
        </InfiniteScroll>
      </div>
    );
  }
}
