import React, { useContext, useEffect, useState } from "react";
import { Col, Divider, Row, Progress, Switch } from "antd";
import WelcomeName from "../utils/WelcomeName";
import { MoreOutlined } from "@ant-design/icons";
import Wallet from "./Wallet";
import InfoBox from "../utils/InfoBox";
import Axios from "axios";
import GeneralCards from "../utils/data/GeneralCards";
import UserContext from "../../context/UserContext";

const GameCodeDash = (props) => {
  const [wallet, setWallet] = useState({});
  const userContext = useContext(UserContext);
  const [ordersMode, setOrdersMode] = useState(true);
  const getWallet = async () => {
    const body = {
      client: userContext.userData.user.market,
      mode: ordersMode ? "production" : "staging",
    };
    console.log("client", userContext.userData.user.market);
    const walletResult = await Axios.post("/api/gshop/getwallet", body);
    console.log("wallet ", walletResult.data);
    var walletmodel = walletResult.data.wallet;

    setWallet(
      createWalletModel(
        walletmodel._id,
        walletmodel.balance,
        walletmodel.amount,
        walletmodel.currency,
        walletmodel.updatedAt
      )
    );

    console.log("wallet ", wallet);
  };

  const onChangeSwitch = (checked) => {
    setOrdersMode(checked);
  };

  function createWalletModel(
    _id,
    balance,
    amount,
    currency,
    client,
    lastpurchase
  ) {
    return {
      _id,
      balance,
      amount,
      currency,
      client,
      lastpurchase,
    };
  }

  useEffect(() => {
    getWallet();
  }, [ordersMode]);

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <WelcomeName />
      <div className="header-secondary">Dashboard client</div>

      {userContext.userData.user.market != "umbrella" ? (
        <GeneralCards user={userContext.userData.user} />
      ) : null}

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {userContext.userData.user.market != "umbrella" ? (
          <Col
            flex="auto"
            // span={6}
            // xs={{order: 1}}
            // sm={{order: 2}}
            // md={{order: 3}}
            // lg={{order: 4}}
          >
            <InfoBox />
          </Col>
        ) : null}
        {userContext.userData.user.market == "gamecode" ? (
          <Col
            flex="auto"
            // span={6}
            // xs={{order: 1}}
            // sm={{order: 2}}
            // md={{order: 3}}
            // lg={{order: 4}}
          >
            <Wallet wallet={wallet} />
          </Col>
        ) : null}

        {userContext.userData.user.market == "topnet" ? (
          wallet.balance != null ? (
            <Col flex="auto">
              <div>
                <Divider orientation="center"> Digital Wallet</Divider>
                <span
                  style={{
                    marginLeft: "7px",
                    marginRight: "7px",
                    fontWeight: "bold",
                  }}
                >
                  Staging / Production
                </span>
                <span>
                  <Switch defaultChecked onChange={onChangeSwitch} />
                </span>
                <div
                  className="shadow-background"
                  style={{
                    backgroundColor: "#09327F",
                    color: "white",
                    padding: "1rem",
                    borderRadius: "5px",
                    minHeight: "120px",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute", top: "0", right: "0" }}>
                      <MoreOutlined />
                    </div>
                    <div style={{ fontSize: "16px", margin: "4px" }}>
                      Active Balance:
                    </div>
                    <div style={{ fontSize: "20px", margin: "4px" }}>
                      {wallet.balance.toFixed(2)} {wallet.currency}
                    </div>
                    <div style={{ margin: "4px" }}>
                      <Progress
                        percent={(
                          ((wallet.amount - wallet.balance) / wallet.amount) *
                          100
                        ).toFixed(2)}
                        strokeColor={"white"}
                        trailColor="#637DAF"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            <div>loading...</div>
          )
        ) : null}
      </Row>
    </div>
  );
};

export default GameCodeDash;
