import React from "react";
import { Menu } from "antd";
import LinkButton from "../utils/LinkButton";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  AppstoreAddOutlined,
  ContainerOutlined,
  CustomerServiceOutlined,
  DatabaseOutlined,
  DollarOutlined,
  ForkOutlined,
  GroupOutlined,
  HistoryOutlined,
  HomeTwoTone,
  LogoutOutlined,
  TrophyOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";

const openMessage = () => {
  localStorage.removeItem("token");
  window.location.reload();
};
const AdminSidebar = () => {
  return (
    <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]}>
      <Menu.Item key="1" icon={<HomeTwoTone />}>
        <LinkButton link="/" buttonText="Dashboard" />
      </Menu.Item>

      <SubMenu key="2" icon={<HistoryOutlined />} title="History">
        <Menu.Item key="3">
          <LinkButton link="/history" buttonText="History" />
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="8" icon={<UploadOutlined />}>
        <LinkButton link="/games" buttonText="Games" />
      </Menu.Item>
      <SubMenu key="9" icon={<AppstoreAddOutlined />} title="Patche Notes">
        <Menu.Item key="10">
          <LinkButton link="/patches/appid/0000" buttonText="My Game Place" />
        </Menu.Item>
        <Menu.Item key="11">
          <LinkButton link="/patches/appid/0001" buttonText="Dashboard" />
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="12" icon={<ContainerOutlined />}>
        <LinkButton link="/tickets" buttonText="Tickets" />
      </Menu.Item>
      <Menu.Item key="13" icon={<CustomerServiceOutlined />}>
        <LinkButton link="/clients" buttonText="Clients" />
      </Menu.Item>
      <Menu.Item key="14" icon={<UserOutlined />}>
        <LinkButton link="/activity" buttonText="Active Users" />
      </Menu.Item>
      <SubMenu key="15" icon={<GroupOutlined />} title="Packs">
        <Menu.Item key="15" icon={<DatabaseOutlined />}>
          <LinkButton link="/packs-data" buttonText="Packs Data" />
        </Menu.Item>
        <Menu.Item key="16" icon={<DollarOutlined />}>
          <LinkButton link="/packs-management" buttonText="Packs Management" />
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="17" icon={<TrophyOutlined />}>
        <LinkButton link="/leaderboard" buttonText="Leaderboard" />
      </Menu.Item>
      <Menu.Item
        key="19"
        icon={<LogoutOutlined />}
        onClick={() => {
          openMessage();
        }}
      >
        <LinkButton link="/" buttonText="Logout" />
      </Menu.Item>
    </Menu>
  );
};

export default AdminSidebar;
