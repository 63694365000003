import React, { useEffect, useState } from "react";
import GraphMGP from "./GraphMGP";
import TableMAT from "./TableMGP";
import TableMGParr from "./TableMGParr";
import { Col, Divider, Spin, Button, Descriptions, Table } from "antd";
import { Line, Bar } from "react-chartjs-2";
import axios from "axios";
import { DatePicker } from "antd";
const MGPD = () => {
  const [lineChart, setLineChart] = useState({});
  const [barChart, setBarChart] = useState({});
  const [WeekTotalAmount, setWeekTotalAmount] = useState(0);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [weeklyStats, setWeeklyStats] = useState([]);
  const formatDate = (d) => {
    var date = new Date(d);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  };

  useEffect(() => {
    const fetchPrices = async () => {
      const res = await axios.get("/api/mygameplace/get/dailystats");

      const data = await res.data.daily.reverse();
      setDataToDisplay([data[data.length - 1]]);
      setWeeklyStats(data);
      var sobflous = 0;
      var clictopay = 0;
      var total_amount = 0;
      data.map((item) => {
        sobflous += item.sobflous;
        clictopay += item.clictopay;
        total_amount += item.Total_amount;
      });
      setWeekTotalAmount(total_amount);
      setLineChart({
        labels: data.map((element) => formatDate(element.Date)),
        datasets: [
          {
            label: "Total amount in DT the 7 past days ",
            data: data.map((element) => element.Total_amount),
            backgroundColor: ["#ffbb11"],
            fill: false,
            borderColor: "rgb(75, 192, 192)",
          },
        ],
      });

      setBarChart({
        labels: ["sobflous", "clictopay"],
        datasets: [
          {
            label: "Retailer in DT the 7 past days ",

            data: [sobflous, clictopay],
            backgroundColor: ["#ffbb11"],
            fill: false,
            borderColor: "rgb(75, 192, 192)",
          },
        ],
      });
    };

    fetchPrices();
  }, []);

  const handleDatePicker = async (date, dateString) => {
    var newData = [];
    var start = new Date(dateString[0]);
    var end = new Date(dateString[1]);
    if (date === null) {
      setDataToDisplay([weeklyStats[weeklyStats.length - 1]]);
    } else {
      const body = { beginDate: start, endDate: end };
      const dataByDate = await axios.post("/api/mygameplace/get/byDate", body);
      setDataToDisplay(dataByDate.data.statsByDate);
    }
  };

  const columns = [
    {
      title: "Total amount ( DT )",
      dataIndex: "Total_amount",
      key: "Total_amount",
      align: "center",
      render: (Total_amount) => <b>{Total_amount.toFixed(3)}</b>,
    },
    {
      title: "Sobflous ( DT )",
      dataIndex: "sobflous",
      key: "sobflous",
      align: "center",
      render: (sobflous) => <b>{sobflous.toFixed(3)}</b>,
    },
    {
      title: "Clic To Pay ( DT )",
      dataIndex: "clictopay",
      key: "clictopay",
      align: "center",
      render: (clictopay) => <b>{clictopay.toFixed(3)}</b>,
    },
    {
      title: "EZpin ( DT )",
      dataIndex: "ezpin",
      key: "ezpin",
      align: "center",
      render: (ezpin) => <b>{ezpin.toFixed(3)}</b>,
    },
    {
      title: "Prepaid Forge ( DT )",
      dataIndex: "prepaidforge",
      key: "prepaidforge",
      align: "center",
      render: (prepaidforge) => <b>{prepaidforge.toFixed(3)}</b>,
    },
    {
      title: "Codapayment ( DT )",
      dataIndex: "codapayment",
      key: "codapayment",
      align: "center",
      render: (codapayment) => <b>{codapayment.toFixed(3)}</b>,
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      align: "center",
      render: (Date) => <div>{formatDate(Date)}</div>,
    },
  ];
  const { RangePicker } = DatePicker;
  return (
    <div>
      <Divider orientation="center">My Game Place history</Divider>

      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "8px",
            alignItems: "center",
          }}
        >
          <span
            style={{
              marginLeft: "7px",
              marginRight: "7px",
              fontWeight: "bold",
            }}
          >
            Pick By Date
          </span>
          <RangePicker onChange={handleDatePicker} />
        </div>
      </div>
      <div style={{ marginTop: "1rem", backgroundColor: "white" }}>
        <div style={{ margin: "1rem" }}>
          <Table dataSource={dataToDisplay} columns={columns} />
        </div>
      </div>

      <div style={{ marginTop: "1rem", backgroundColor: "white" }}>
        <div style={{ margin: "1rem" }}>
          Week Total Amount :<b> {WeekTotalAmount.toFixed(3)} </b> DT
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "40%" }}>
            <Line
              data={lineChart}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Cryptocurrency prices",
                  },
                  legend: {
                    display: true,
                    position: "bottom",
                  },
                },
              }}
            />
          </div>
          <div style={{ width: "40%" }}>
            <Bar
              data={barChart}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Cryptocurrency prices",
                  },
                  legend: {
                    display: true,
                    position: "bottom",
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MGPD;
