import React, { useState } from 'react';
import { Button, Modal } from "antd";
import PacksCreation from "./PacksCreation";

const OfferCreation = () => {
    const [showCreate, setShowCreate] = useState(false);
    return (
        <div>
            <Button style={{ margin: '5px' }} color="primary" shape="round" onClick={() => setShowCreate(true)}>
                Create Offer
            </Button>
            <Modal
                title={<div style={{ color: "white" }}>Offer Creation</div>}
                centered
                visible={showCreate}
                footer={null}
            // onOk={() => setShowCreate(false)}
            // onCancel={() => setShowCreate(false)}
            >
                <PacksCreation />
            </Modal>
        </div>
    );
};

export default OfferCreation;
