import React, { useContext } from "react";
import { NotificationFilled } from "@ant-design/icons";
import UserContext from "../../context/UserContext";
import Avatar from "antd/lib/avatar/avatar";
import { Badge, Menu, Dropdown } from "antd";
import Notifications from "../notifications/Notifications";
import Messages from "../messages/Messages";
import ProfileDropdown from "../profiles/ProfileDropdown";
const MenuProfile = <></>;
const Profile = () => {
   const userContext = useContext(UserContext);
   return (
      <div
         style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
         }}
      >
         <Messages />
         <Notifications />
      </div>
   );
};

export default Profile;
