import React, { useState, useEffect } from "react";
import "./App.css";
import AppMain from "./components/AppMain";
import Login from "./components/auth/Login";
import { LoadingOutlined } from "@ant-design/icons";
import CacheBuster from "./CacheBuster";
import UserContext from "./context/UserContext";
import GamesContext from "./context/GamesContext";
import PurchaseContext from "./context/PurchaseContext";
import UsersContext from "./context/UsersContext";

import Axios from "axios";
import UnauthorizedPage from "./components/Errors/403Page";
import Request from "./components/auth/Request";
import Auth from "./components/auth/Auth";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [purchases, setPurchases] = useState([]);
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
  });
  const [users, setUsers] = useState({
    usersLoggedIn: [],
  });
  const [games, setGames] = useState([]);
  useEffect(() => {
    const checkLoggedIn = async () => {
      let token = localStorage.getItem("token");
      if (token === null) {
        localStorage.setItem("token", "");
        token = "";
        setLoadingUser(false);
      }
      const tokenRes = await Axios.post("/api/users/validate", null, {
        headers: { "x-auth-token": token },
      });
      if (!tokenRes.data.error) {
        const userRes = await Axios.get("/api/users/", {
          headers: { "x-auth-token": token },
        });
        setUserData({
          token: token,
          user: userRes.data,
        });
        setLoadingUser(false);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
        setLoadingUser(false);
      }
    };
    checkLoggedIn();
  }, []);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <UsersContext.Provider value={{ users, setUsers }}>
            <PurchaseContext.Provider value={{ purchases, setPurchases }}>
              <UserContext.Provider value={{ userData, setUserData }}>
                <div>
                  {loadingUser ? (
                    <div className="progress">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {antIcon}Fetching data..
                      </div>
                    </div>
                  ) : (
                    <div id="container" className="page-container">
                      {loggedIn ? <AppMain /> : <Auth />}
                    </div>
                  )}
                </div>
              </UserContext.Provider>
            </PurchaseContext.Provider>
          </UsersContext.Provider>
        );
      }}
    </CacheBuster>
  );
}

export default App;
