import axios from "axios";

const service = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "/api/markets"
      : `http://${window.location.hostname}:5000/api/markets`,

  withCredentials: true,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  },
});

const errHandler = (err) => {
  if (err.response && err.response.data) {
    throw err.response.data.message;
  }
  throw err;
};

export default {
  service: service,
  getAll() {
    return service
      .get("/")
      .then((res) => res.data)
      .catch(errHandler);
  },
  createMarket(market) {
    return service
      .post("/create", market)
      .then((res) => res.data)
      .catch(errHandler);
  },
  getById(marketId) {
    return service
      .get("/", marketId)
      .then((res) => res.data)
      .catch(errHandler);
  },
};
