import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { convertFromHTML, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import ReactHtmlParser from "react-html-parser";
import { notification, Divider, DatePicker } from "antd";
import Axios from "axios";
import { Form, Input, Button, Select } from "antd";

const { Option } = Select;
// const layout = {
//   labelCol: { span: 8 },
//   wrapperCol: { span: 16 },
// };
// const tailLayout = {
//   wrapperCol: { offset: 8, span: 16 },
// };
class PatchEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      patchNumber: "",
      editorState: "",
      contentState: "",
      date: "",
      type: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    console.log(this.props.patch);
    if (this.props.patch) {
      this.setState({
        title: this.props.patch.title,
        type: this.props.patch.type,
        patchNumber: this.props.patch.patchNumber,
        contentState: this.props.patch.content,
        date: this.props.patch.date,
      });
    } else {
      console.log("CREATE");
    }
  }
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onContentStateChange = (contentState) => {
    this.setState(
      {
        contentState,
      },
      () => {
        console.log(draftToHtml(this.state.contentState));
      }
    );
  };
  openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Patch Note Created",
      description: "You have successfully added a new patch note.",
    });
  };
  openNotificationUpdate = (type) => {
    notification[type]({
      message: "Patch Note Updated",
      description: "You have successfully updated a patch note.",
    });
  };

  createPatch() {
    const patch = {
      title: this.state.title,
      content: draftToHtml(this.state.contentState),
      patchNumber: this.state.patchNumber,
      date: this.state.date,
      type: this.state.type,
      app: this.props.app,
    };
    Axios.post("/api/patch-notes/create", patch)
      .then((res) => {
        this.props.setVisibility(false);
        // this.props.updatePatches(res.data);
        this.props.refresh();
        this.openNotificationWithIcon("success");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updatePatch() {
    const patch = {
      _id: this.props.patch._id,
      title: this.state.title,
      content: draftToHtml(this.state.contentState),
      patchNumber: this.state.patchNumber,
      date: this.state.date,
      type: this.state.type,
    };
    Axios.post("/api/patch-notes/update", patch)
      .then((res) => {
        this.props.setVisibility(false);
        // this.props.updatePatches(res.data);

        this.openNotificationUpdate("warning");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  onChangeDate = (date, dateString) => {
    const d = new Date(date);
    this.setState({
      date: d,
    });
  };
  onChangeType = (type) => {
    this.setState({
      type: type,
    });
  };
  render() {
    return (
      <div>
        <Divider orientation="center">General</Divider>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form>
            <Form.Item
              name="Title"
              label="Patch Title"
              rules={[{ required: true }]}
            >
              <Input
                value={this.state.title}
                placeholder={this.state.title}
                name="title"
                required
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item
              name="patchNumber"
              label="Patch Number"
              rules={[{ required: true }]}
            >
              <Input
                value={Number(this.state.patchNumber)}
                placeholder={this.state.patchNumber}
                type="number"
                name="patchNumber"
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item name="type" label="Type" rules={[{ required: true }]}>
              <Select
                name="type"
                defaultValue={this.state.type}
                placeholder={this.state.type}
                onChange={this.onChangeType}
                allowClear
              >
                <Option value="release">New Product</Option>
                <Option value="patch">Patch Release</Option>
                <Option value="minor">Minor Release</Option>
                <Option value="major">Major Release</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="date"
              label="Scheduled Date"
              rules={[{ required: true }]}
            >
              <DatePicker
                name="date"
                value={this.state.date}
                placeholder={this.state.date}
                style={{ width: "100%" }}
                onChange={this.onChangeDate}
              />
            </Form.Item>
          </Form>
        </div>
        <Divider orientation="center">Content</Divider>

        <Editor
          // contentState={convertFromHTML(this.state.contentState)}
          toolbarClassName="patch-editor-toolbar"
          wrapperClassName="patch-editor-wrapper"
          editorClassName="patch-editor"
          onEditorStateChange={this.onEditorStateChange}
          onContentStateChange={this.onContentStateChange}
          onChange={this.onContentStateChange}
        />
        {this.props.patch ? (
          <div style={{ marginTop: "2%" }}>
            {ReactHtmlParser(this.props.patch.content)}
          </div>
        ) : null}
        {this.props.patch ? (
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              shape="round"
              style={{ margin: "1%" }}
              onClick={() => {
                this.updatePatch();
              }}
            >
              Update
            </Button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              shape="round"
              style={{ margin: "1%" }}
              onClick={() => {
                this.createPatch();
              }}
            >
              Create
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default PatchEditor;
