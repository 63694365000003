import React from "react";
import Icon from "../../assets/images/svg/gaming-commentator.svg";
import { Button } from "antd";
import { Divider } from "antd";

const InfoBox = () => {
  return (
    <div>
      <Divider orientation="center">Support</Divider>
      <div
        style={{
          width: "100%",
          backgroundColor: "#F0F2F5",
          padding: "12px",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <div className="title">sign a ticket</div>
            <div>
              <p>
                {/* <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span> */}
              </p>
            </div>
            <div>
              <Button type="primary"> Apply Now </Button>
            </div>
          </div>
          <div style={{ width: "100px", marginLeft: "10px" }}>
            <img src={Icon} alt="" width={"100%"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
