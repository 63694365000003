import { Col, Row, Tabs } from "antd";
import React from "react";
import AllMessages from "./AllMessages";
import Contacts from "./Contacts";
import Conversations from "./Conversations";
const { TabPane } = Tabs;

export default function MessagesModal() {
  return (
    <div style={{ minHeight: "360px" }}>
      <div>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Conversations" key="1">
                <Conversations />
              </TabPane>
              <TabPane tab="Contacts" key="2">
                <Contacts />
              </TabPane>
            </Tabs>
          </Col>

          <Col span={12}>
            <AllMessages />
          </Col>
        </Row>
      </div>
    </div>
  );
}
