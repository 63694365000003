import { Button, Card, Statistic } from "antd";
import React from "react";
import { MoreOutlined } from "@ant-design/icons";
export default function StatisticsCard(props) {
  return (
    <Card style={{ backgroundColor: "transparent" }} bordered={false}>
      <div style={{ position: "absolute", left: "0", width: "100%" }}>
        <Button
          type="text"
          shape="circle"
          icon={<MoreOutlined style={{ color: "white" }} />}
          size="small"
        />
      </div>

      <Statistic
        title={
          <div style={{ width: "100%", color: "black" }}>{props.title}</div>
        }
        value={props.value}
        valueStyle={{ color: "black" }}
        suffix={props.suffix}
      />
      {props.subText}
    </Card>
  );
}
