import React, { Component, useEffect, useState, useContext } from "react";
import { Button, Col, Result, Row } from "antd";
import axios from "axios";
import CardSkeleton from "./CardSkeleton";
import CardGame from "./CardGame";
import Purchases from "../offers/GamePurchases";
import GamesContext from "../../context/GamesContext";
import UserContext from "../../context/UserContext";
import image404 from "../../assets/images/404-error-not-found.png";
const Games = (props) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [singlePage, setSinglePage] = useState(true);
  const [loading, setLoading] = useState(true);
  const userContext = useContext(UserContext);
  // const [games, setGames] = useState([]);
  const gamesContext = useContext(GamesContext);
  useEffect(() => {
    if (gamesContext.games.length !== 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, []);
  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      {userContext.userData.user.role === "client" ? (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited still does not exist."
          extra={<Button type="primary">Back Home</Button>}
        />
      ) : (
        <div>
          {singlePage === true ? (
            <div className="header-secondary">Games List</div>
          ) : null}
          {loading ? (
            <Row gutter={16}>
              <Col flex="3" className="grid-games">
                <CardSkeleton />
              </Col>
              <Col flex="3" className="grid-games">
                <CardSkeleton />
              </Col>
              <Col flex="3" className="grid-games">
                <CardSkeleton />
              </Col>
              <Col flex="3" className="grid-games">
                <CardSkeleton />
              </Col>
            </Row>
          ) : (
            <div className="site-card-wrapper">
              <div>
                <Row gutter={16}>
                  <Col
                    flex="3"
                    className="grid-games"
                    onClick={() => {
                      setSelectedGame(gamesContext.games[0]);
                    }}
                  >
                    <CardGame game={gamesContext.games[0]} />
                  </Col>
                  <Col
                    flex="3"
                    className="grid-games"
                    onClick={() => {
                      setSelectedGame(gamesContext.games[1]);
                    }}
                  >
                    <CardGame game={gamesContext.games[1]} />
                  </Col>
                  <Col
                    flex="3"
                    className="grid-games"
                    onClick={() => {
                      setSelectedGame(gamesContext.games[3]);
                    }}
                  >
                    <CardGame game={gamesContext.games[3]} />
                  </Col>

                  <Col
                    flex="3"
                    className="grid-games"
                    onClick={() => {
                      setSelectedGame(gamesContext.games[4]);
                    }}
                  >
                    <CardGame game={gamesContext.games[4]} />
                  </Col>
                </Row>
                <Purchases selectedGame={selectedGame} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Games;
