import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import ClientSidebar from "./ClientSidebar";
import AdminSidebar from "./AdminSidebar";
import SuperSidebar from "./SuperSiderbar";

const DashboardMenu = () => {
  const userContext = useContext(UserContext);
  // console.log(userContext)
  return (
    <span>
      {userContext.userData.user.role === "client" ? (
        <ClientSidebar />
      ) : userContext.userData.user.role === "admin" ? (
        <AdminSidebar />
      ) : (
        <SuperSidebar />
      )}
    </span>
  );
};

export default DashboardMenu;
