import React, { useContext, useEffect, useState } from "react";

import { BackTop, Layout } from "antd";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import LogoGalactech from "./../assets/images/logos/logo_icon.png";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Dashboard from "./dashboard/Dashboard";

import Games from "./games/Games";

import Purchases from "./purchases/Purchases";

import Client from "./clients/Client";
import Patches from "./patchnotes/Patches";
import Tickets from "./tickets/Tickets";
import io from "socket.io-client";
import Profile from "./utils/Profile";
import UserContext from "../context/UserContext";
import GamesContext from "../context/GamesContext";

import ActiveUsers from "./users/ActiveUsers";
import PacksData from "./packs/PacksData";
import Axios from "axios";
import Leaderboard from "./leaderboard/Leaderboard";
import PurchaseLanding from "./purchases/PurchaseLanding";
import PacksManagement from "./packs/PacksManagement";
import DashboardMenu from "./dashboard/DashboardMenu";
import GameCodeDash from "./Wallet/ClientDashboard";
import PurchaseClient from "./purchases/PurchaseClient";
import InvoiceManagement from "./invoice/InvoiceManagement";
import purchaseService from "../services/purchaseService";
import UsersContext from "../context/UsersContext";
import PurchaseContext from "../context/PurchaseContext";
import PrepaidInformation from "./providers/Providerformation";
import ErrorResult from "./utils/ErrorResult";
import OMGD from "./gdash/GameCodeControllerPage";
import UmbrellaController from "./gdash/UmbrellaControllerPage";
import MGPD from "./gdash/MGPD";
import errorPage from "../components/Errors/403Page";

import Shop from "./Shop/shop";
import Refund from "./refund/Refund";
import MgpRefund from "./refund/MgpRefund";

import UnauthorizedPage from "../components/Errors/403Page";
import UmbrellaPurchases from "../components/purchases/UmbrellaPurchases";
// import { Input } from "antd";

const { Header, Content, Footer, Sider } = Layout;

// const { Search } = Input;

const key = "updatable";

const openMessage = () => {
  localStorage.removeItem("token");
};

const AppMain = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState("0");
  const [users, setUsers] = useState([]);
  const userContext = useContext(UserContext);
  const usersContext = useContext(UsersContext);
  const gamesContext = useContext(GamesContext);

  const purchasesContext = useContext(PurchaseContext);
  const getGames = () => {
    Axios.get("api/games")
      .then((res) => {
        gamesContext.setGames(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPurchases = () => {
    purchaseService.getAll().then((res) => {
      purchasesContext.setPurchases(res);
    });
  };
  useEffect(() => {
    // getGames();
    getPurchases();
    var user = JSON.stringify(userContext.userData.user);
    const ENDPOINT = "localhost:5000";
    // const ENDPOINT = "https://boardgain.xyz";
    // var socket = io(ENDPOINT);

    // socket.emit("Dashboard Init", user);
    // socket.emit("join", { user: userContext.userData.user });

    // socket.on("getJoined", (users) => {
    //   setUsers(users);

    //   usersContext.setUsers({
    //     usersLoggedIn: users,
    //   });
    //   console.log(users);
    // });
  }, []);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  return (
    <BrowserRouter>
      <BackTop />
      {!userContext.userData.user.verified ? (
        <ErrorResult />
      ) : (
        <Layout>
          <Sider breakpoint="md" trigger={null} collapsible fixed>
            <div className="logo">
              <div></div>
              <img src={LogoGalactech} alt="logo galactech" />
            </div>
            <DashboardMenu />
          </Sider>
          <Layout className="site-layout">
            <Content style={{ margin: "24px 16px 0" }}>
              <Switch>
                <Route
                  exact
                  path="/"
                  component={
                    userContext.userData.user.role !== "client"
                      ? Dashboard
                      : GameCodeDash
                  }
                />
                <Route
                  exact
                  path="/refund"
                  component={
                    userContext.userData.user.role !== "client"
                      ? MgpRefund
                      : Refund
                  }
                />
                <Route
                  exact
                  path="/history/mygameplace"
                  component={
                    userContext.userData.user.role !== "client"
                      ? Purchases
                      : errorPage
                  }
                />
                <Route
                  exact
                  path="/history/:pagename"
                  component={PurchaseLanding}
                />

                {/* <Route
                  exact
                  path="/history/:page"
                  component={UmbrellaPurchases}
                /> */}
                {/* <Route
                  exact
                  path="/history/orange"
                  component={PurchaseLanding}
                /> */}
                <Route
                  exact
                  path="/provider/:provider"
                  component={
                    userContext.userData.user.role !== "client"
                      ? PrepaidInformation
                      : null
                  }
                />

                {/* <Route exact path="/refund/" component={Refund} /> */}
                <Route exact path="/shop" component={Shop} />
                {/* <Route exact path="/history/orange" component={Purchases} /> */}
                {/* <Route
                  exact
                  path="/history/sobflous"
                  component={PurchasesSobflous}
                /> */}

                <Route exact path="/games" component={Games} />
                <Route exact path="/clients" component={Client} />
                <Route
                  exact
                  path="/patches/appid/0000"
                  component={() => <Patches app="MY.GAME.PLACE" />}
                />
                <Route
                  exact
                  path="/patches/appid/0001"
                  component={() => <Patches app="DASHBOARD" />}
                />
                {/*<Route*/}
                {/*    exact*/}
                {/*    path="/transaction-history/orange"*/}
                {/*    component={TransactionHistory}*/}
                {/*/>*/}
                {/*<Route exact path="/transaction-history/sobflous" component={TransactionSobflousHistory}/>*/}
                <Route exact path="/tickets" component={Tickets} />
                <Route exact path="/activity" component={ActiveUsers} />
                <Route exact path="/packs-data" component={PacksData} />
                <Route
                  exact
                  path="/packs-management"
                  component={PacksManagement}
                />
                <Route exact path="/invoices" component={InvoiceManagement} />

                <Route
                  exact
                  path="/leaderboard"
                  component={Leaderboard}
                  // component={() => (
                  //   <Leaderboard
                  //     client={
                  //       userContext.userData.user.role !== "client"
                  //         ? false
                  //         : true
                  //     }
                  //   />
                  // )}
                />

                <Route
                  exact
                  path="/gamecode"
                  component={
                    userContext.userData.user.role !== "client"
                      ? OMGD
                      : UnauthorizedPage
                  }
                />

                <Route
                  exact
                  path="/PExperimental"
                  component={
                    userContext.userData.user.role !== "client"
                      ? UmbrellaController
                      : UnauthorizedPage
                  }
                />
                <Route exact path="/mygameplace" component={MGPD} />
              </Switch>
            </Content>
            <Footer style={{ textAlign: "center" }}>Galactech 2020</Footer>
          </Layout>
        </Layout>
      )}
    </BrowserRouter>
  );
};
export default AppMain;
