import React from "react";
import { Menu, Divider } from "antd";
import LinkButton from "../utils/LinkButton";
import SubMenu from "antd/lib/menu/SubMenu";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import {
  AppstoreAddOutlined,
  ContainerOutlined,
  CustomerServiceOutlined,
  DatabaseOutlined,
  DollarOutlined,
  GroupOutlined,
  HistoryOutlined,
  HomeTwoTone,
  LogoutOutlined,
  TrophyOutlined,
  UploadOutlined,
  UserOutlined,
  LineChartOutlined,
  SettingOutlined,
  ReloadOutlined,
  CodeOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";

const openMessage = () => {
  localStorage.removeItem("token");
  window.location.reload();
};
const SuperSidebar = () => {
  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={["0"]}>
      <Menu.Item key="1" icon={<HomeTwoTone />}>
        <LinkButton link="/" buttonText="Dashboard" />
      </Menu.Item>
      <Divider plain style={{ color: "white" }}>
        Marketing
      </Divider>

      <SubMenu key="51" icon={<HistoryOutlined />} title="History">
        <Menu.Item key="2">
          <LinkButton link="/history/mygameplace" buttonText="MyGamePlace" />
        </Menu.Item>
        <Menu.Item key="60">
          <LinkButton link="/history/gamecode" buttonText="GameCode" />
        </Menu.Item>
        <Menu.Item key="51">
          <LinkButton link="/history/orange" buttonText="MGP Orange" />
        </Menu.Item>
        <Menu.Item key="52">
          <LinkButton link="/history/umbrella" buttonText="Umbrella" />
        </Menu.Item>
      </SubMenu>

      {/*<SubMenu key="2" icon={<HistoryOutlined />} title="History">
         <Menu.Item key="4">
          <LinkButton link="/history/sobflous" buttonText="History Sobflous" />
        </Menu.Item> 
      </SubMenu>*/}

      {/*<SubMenu*/}
      {/*    key="5"*/}
      {/*    icon={<ForkOutlined/>}*/}
      {/*    title="Transactions"*/}
      {/*>*/}
      {/*    <Menu.Item key="6">*/}
      {/*        <LinkButton*/}
      {/*            link="/transaction-history/orange"*/}
      {/*            buttonText="Transactions Orange"*/}
      {/*        />*/}
      {/*    </Menu.Item>*/}
      {/*    <Menu.Item key="7">*/}
      {/*        <LinkButton*/}
      {/*            link="/transaction-history/sobflous"*/}
      {/*            buttonText="Transactions Status"*/}
      {/*        />*/}
      {/*    </Menu.Item>*/}
      {/*</SubMenu>*/}
      <SubMenu key="30" icon={<LineChartOutlined />} title="G-Dash">
        <Menu.Item key="32">
          <LinkButton link="/mygameplace" buttonText="My Game Place" />
        </Menu.Item>
        <Menu.Item key="33">
          <LinkButton link="/gamecode" buttonText="Game Code" />
        </Menu.Item>
        <Menu.Item key="34">
          <LinkButton link="/PExperimental" buttonText="Umbrella" />
        </Menu.Item>
      </SubMenu>

      <SubMenu key="40" icon={<SettingOutlined />} title="Provider Support">
        <Menu.Item key="41" icon={<ReloadOutlined />}>
          <LinkButton link="/provider/ezpin" buttonText="EZ Spin" />
        </Menu.Item>
        <Menu.Item key="42" icon={<MoneyCollectOutlined />}>
          <LinkButton link="/provider/prepaidforge" buttonText="Prepaidforge" />
        </Menu.Item>
        <Menu.Item key="43" icon={<CodeOutlined />}>
          <LinkButton link="/provider/codapayment" buttonText="Code Payment" />
        </Menu.Item>
      </SubMenu>
      <SubMenu key="9" icon={<AppstoreAddOutlined />} title="Patche Notes">
        <Menu.Item key="10">
          <LinkButton link="/patches/appid/0000" buttonText="My Game Place" />
        </Menu.Item>
        <Menu.Item key="11">
          <LinkButton link="/patches/appid/0001" buttonText="Dashboard" />
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="17" icon={<TrophyOutlined />}>
        <LinkButton link="/leaderboard" buttonText="Leaderboard" />
      </Menu.Item>
      <Menu.Item key="13" icon={<CustomerServiceOutlined />}>
        <LinkButton link="/clients" buttonText="Clients" />
      </Menu.Item>

      {/* <SubMenu key="15" icon={<GroupOutlined />} title="Packs">
                <Menu.Item key="15" icon={<DatabaseOutlined />}>
                    <LinkButton link="/packs-data" buttonText="Packs Data" />
                </Menu.Item>
                <Menu.Item key="16" icon={<DollarOutlined />}>
                    <LinkButton link="/packs-management" buttonText="Packs Management" />
                </Menu.Item>
            </SubMenu>
 */}
      {/*  <div style={{ padding: "1rem" }}>
                <Title level={4} type="primary" style={{ marginLeft: "1rem", color: "white", }}>
                    Technical
                </Title>
            </div> */}
      <Divider plain style={{ color: "white" }}>
        Technical
      </Divider>

      <Menu.Item key="12" icon={<ContainerOutlined />}>
        <LinkButton link="/tickets" buttonText="Tickets" />
      </Menu.Item>
      <Menu.Item key="8" icon={<UploadOutlined />}>
        <LinkButton link="/games" buttonText="Games" />
      </Menu.Item>
      <Menu.Item key="14" icon={<UserOutlined />}>
        <LinkButton link="/activity" buttonText="Active Users" />
      </Menu.Item>
      <Menu.Item
        key="19"
        icon={<LogoutOutlined />}
        onClick={() => {
          openMessage();
        }}
      >
        <LinkButton link="/" buttonText="Logout" />
      </Menu.Item>
    </Menu>
  );
};

export default SuperSidebar;
