import React, { Component, useEffect, useState } from "react";
import {
  Card,
  Col,
  Divider,
  notification,
  Row,
  Skeleton,
  Spin,
  Statistic,
  Switch,
} from "antd";
import {
  ArrowUpOutlined,
  CheckCircleOutlined,
  DollarOutlined,
  DollarTwoTone,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import axios from "axios";
import StatisticsCard from "./StatisticsCard";
import { Select } from "antd";
import CenteredSelction from "./CenteredSelction";
import Item from "antd/lib/list/Item";
import purchaseService from "../../../services/purchaseService";
import MarketFilter from "../MarketFilter";
import Text from "antd/lib/typography/Text";

const { Option } = Select;

const openNotificationWithIcon = (type, title, description) => {
  notification[type]({
    message: [title],
    description: [description],
    placement: "topRight",
    duration: 0,
    icon: <DollarTwoTone />,
  });
};
const openNotificationWarning = (type) => {
  notification[type]({
    message: "Welcome",
    description:
      "This dashboard is still work in progress, if you face any bug please report to the developers\nVersion 1.8.1",
  });
};
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const GeneralCards = (props) => {
  const [selectedGame, setSelectedGame] = useState("");
  const [successPurchases, setSuccessPurchases] = useState(0);
  const [failedPurchases, setFailedPurchases] = useState(0);
  const [purchases, setPurchases] = useState([]);
  const [purchasesToDisplay, setPurchasestoDisplay] = useState([]);
  const [loading, setLoading] = useState([]);
  const [revenue, setRevenue] = useState(0);
  const [daily, setDaily] = useState(0);
  const [rangeDate, setRangeDate] = useState([]);
  const [purchasesByDate, setpurchasesByDate] = useState([]);
  const [market, setMarket] = useState("mgp");
  const [retailer, setRetailer] = useState("all");
  const [ordersMode, setOrdersMode] = useState(true);
  const [stat, setStat] = useState({});

  const getAllPurchases = () => {
    if (props.user.role != "client") {
      if (market == "mgp") {
        setPurchasestoDisplay([]);
        purchaseService.getAll().then((res) => {
          setPurchases(res.purchases);
          // setPurchasestoDisplay(res.purchases);
        });
      } else if (market == "orange") {
        console.log("orange");
        setPurchasestoDisplay([]);
        axios.get("/api/purchase/orange/purchases").then((res) => {
          var data = [];
          data = res.data.filter((value) => value.retailer == "orange");
          setPurchases(data);
          setPurchasestoDisplay(data);
        });
      } else if (market == "lybiana") {
        setPurchasestoDisplay([]);
        // axios.get("/api/purchase/get/lybianaPurchases").then((res) => {
        //   setPurchases(res.data);
        //   setPurchasestoDisplay(res.data);

        //   //setLoading(false);
        // });
      }
    } else {
      if (props.user.market == "gamecode") {
        axios.get("/api/gamecode/stat").then((res) => {
          setStat(res.data.stat);
        });
      } else if (props.user.market == "topnet") {
        const body = {
          market: props.user.market,
          mode: ordersMode ? "production" : "staging",
        };
        axios.post("/api/gshop/stats/generalstats", body).then((res) => {
          setStat(res.data.result.result);
        });
      }
    }
  };

  useEffect(() => {
    setGlobalVerbilas();
  }, [purchasesToDisplay, stat]);

  const getByDate = (date) => {
    setRangeDate(date);
    var newData = [];
    // setphoneNumber("");
    if (date === null) {
      setRangeDate([]);
      // setPurchases(purchasesState);
      //getPurchasesRevenue(purchases);
      setPurchasestoDisplay(purchases);

      setpurchasesByDate([]);
      //setScucessCheck(false);
    } else {
      //&é"'(setScucessCheck(false);
      var startDate = new Date(date[0]);
      var endDate = new Date(date[1]);
      for (var i = 0; i < purchases.length; i++) {
        if (
          new Date(purchases[i].creation_date) >= startDate &&
          new Date(purchases[i].creation_date) <= endDate
        ) {
          newData.push(purchases[i]);
        }
      }

      setPurchasestoDisplay(newData);
      setpurchasesByDate(newData);
      // getPurchasesRevenue(newData);
    }
  };

  const getByRetailer = (value) => {
    setRetailer(value.retailer);

    if (!props.client) {
      let filtredData = [];
      if (purchasesByDate.length > 0) filtredData = purchasesByDate;
      else filtredData = purchasesToDisplay;
      if (value.retailer === "all") {
        setRangeDate([]);
        setPurchasestoDisplay(purchases);
        //getAllPurchases();
        //setGlobalVerbilas();
      } else {
        const purch = purchases.filter(
          (item) => item.payment_data.method == value.retailer
        );

        setPurchasestoDisplay(purch);
        //setGlobalVerbilas();
      }
    }
  };

  const getByMarket = (value) => {
    setMarket(value);
  };
  useEffect(() => {
    getAllPurchases();
  }, [market, ordersMode]);

  const formatDate = (d) => {
    var date = new Date(d);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      "  " +
      strTime
    );
  };

  const setGlobalVerbilas = () => {
    let rev = 0;
    let incom = 0;
    let daily = 0;
    let j = 0;
    let f = 0;
    let filtredData = [];
    // if (purchasesByDate.length > 0) filtredData = purchasesByDate;
    // else filtredData = purchasesToDisplay;
    filtredData = purchasesToDisplay;

    if (props.user.role != "client" && market == "mgp") {
      for (var i = 0; i < filtredData.length; i++) {
        if (filtredData[i].payment_data.status.toUpperCase() === "SUCCESS") {
          j++;

          rev += parseInt(filtredData[i].payment_data.total_amount);
          if (
            new Date(filtredData[i].creation_date).getDate() >=
              new Date().getDate() &&
            new Date(filtredData[i].creation_date).getMonth() ===
              new Date().getMonth()
          ) {
            daily += parseInt(filtredData[i].payment_data.total_amount);
          }
        } else {
          f++;
        }
      }
    } else {
      for (var i = 0; i < filtredData.length; i++) {
        if (
          filtredData[i].purchase_info.status.toUpperCase() === "FULFILLED" ||
          filtredData[i].purchase_info.status.toUpperCase() === "REFUNDED"
        ) {
          j++;

          rev += parseInt(filtredData[i].purchase_info.price.amount);
          // if (
          //   new Date(filtredData[i].creation_date).getDate() >=
          //     new Date().getDate() &&
          //   new Date(filtredData[i].creation_date).getMonth() ===
          //     new Date().getMonth()
          // ) {
          //   daily += parseInt(filtredData[i].purchase_info.price.amount);
          // }
        } else {
          f++;
        }
      }
    }

    if (market == "lybiana" || props.user.market == "gamecode") {
      setDaily(daily / 1000);
      setRevenue(rev / 1000);
    } else {
      setRevenue(stat.income);

      //    setRevenue(rev);
    }

    setFailedPurchases(f);
    setSuccessPurchases(j);
  };

  const onChangeSwitch = (checked) => {
    console.log("check", checked);
    setOrdersMode(checked);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 17 }} spin />;

  const success = stat.revenu != undefined ? stat.success : 0;
  const fail = stat.revenu != undefined ? stat.fail : 0;
  const total = stat.revenu != undefined ? stat.total_purchases : 0;
  return (
    <div className="box-shadow">
      {loading ? (
        <div style={{ padding: "1rem" }}>
          <Divider orientation="center">General</Divider>
          {props.user.market == "topnet" ? (
            <div>
              <span
                style={{
                  marginLeft: "7px",
                  marginRight: "7px",
                  fontWeight: "bold",
                }}
              >
                Staging / Production
              </span>
              <span>
                <Switch defaultChecked onChange={onChangeSwitch} />
              </span>
            </div>
          ) : null}
          {props.user.role != "client" ? (
            <MarketFilter
              getByRetailer={getByRetailer}
              getByDate={getByDate}
              getByMarket={getByMarket}
            />
          ) : null}
          {props.user.role != "client" ? (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} lg={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#1890FF",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <StatisticsCard
                      subText={
                        <div>
                          <CalendarOutlined
                            style={{
                              color: "white",
                              margin: "auto",
                            }}
                          />
                          <Text
                            type="disabled"
                            style={{
                              margin: "auto",
                              color: "white",
                              marginLeft: "3px",
                            }}
                          >
                            {purchasesToDisplay[
                              purchasesToDisplay.length - 1
                            ] != undefined ? (
                              formatDate(
                                purchasesToDisplay[
                                  purchasesToDisplay.length - 1
                                ].creation_date
                              )
                            ) : (
                              <Spin indicator={antIcon} />
                            )}
                          </Text>
                        </div>
                      }
                      title="Income"
                      value={revenue}
                      suffix={props.client ? "LD" : "TND"}
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <DollarOutlined
                      className="blue-gradiant"
                      style={{ fontSize: "28px", padding: "1rem" }}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "rgb(51, 203, 85)",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <StatisticsCard
                      subText={
                        <div>
                          <CalendarOutlined
                            style={{
                              color: "white",
                              margin: "auto",
                            }}
                          />
                          <Text
                            type="disabled"
                            style={{
                              margin: "auto",
                              color: "white",
                              marginLeft: "3px",
                            }}
                          >
                            {purchasesToDisplay[
                              purchasesToDisplay.length - 1
                            ] != undefined ? (
                              formatDate(
                                purchasesToDisplay[
                                  purchasesToDisplay.length - 1
                                ].creation_date
                              )
                            ) : (
                              <Spin indicator={antIcon} />
                            )}
                          </Text>
                        </div>
                      }
                      title={"Success"}
                      value={successPurchases}
                      suffix={
                        "(" +
                        Math.round(
                          (Number(successPurchases) * 100) /
                            Number(purchasesToDisplay.length)
                        ) +
                        "%)"
                      }
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <CheckCircleOutlined
                      className="green-gradiant"
                      style={{ fontSize: "28px", padding: "1rem" }}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "rgb(227, 94, 107)",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <StatisticsCard
                      subText={
                        <div>
                          <CalendarOutlined
                            style={{
                              color: "white",
                              margin: "auto",
                            }}
                          />
                          <Text
                            type="disabled"
                            style={{
                              margin: "auto",
                              color: "white",
                              marginLeft: "3px",
                            }}
                          >
                            {/* {new Date(purchases[1].creation_date)} */}
                            {/* {new Date(initialDate)} */}
                            {purchasesToDisplay[
                              purchasesToDisplay.length - 1
                            ] != undefined ? (
                              formatDate(
                                purchasesToDisplay[
                                  purchasesToDisplay.length - 1
                                ].creation_date
                              )
                            ) : (
                              <Spin indicator={antIcon} />
                            )}
                          </Text>
                        </div>
                      }
                      title={"Failed"}
                      value={failedPurchases}
                      suffix={
                        "(" +
                        Math.round(
                          (Number(failedPurchases) * 100) /
                            Number(purchasesToDisplay.length)
                        ) +
                        "%)"
                      }
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <ExclamationCircleOutlined
                      className="red-gradiant"
                      style={{ fontSize: "28px", padding: "1rem" }}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "rgb(255, 205, 54)",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <StatisticsCard
                      subText={
                        <div>
                          <CalendarOutlined
                            style={{
                              color: "white",
                              margin: "auto",
                            }}
                          />
                          <Text
                            type="disabled"
                            style={{
                              margin: "auto",
                              color: "white",
                              marginLeft: "3px",
                            }}
                          >
                            {purchasesToDisplay[
                              purchasesToDisplay.length - 1
                            ] != undefined ? (
                              formatDate(
                                purchasesToDisplay[
                                  purchasesToDisplay.length - 1
                                ].creation_date
                              )
                            ) : (
                              <Spin indicator={antIcon} />
                            )}
                          </Text>
                        </div>
                      }
                      title="All"
                      value={purchasesToDisplay.length}
                      suffix="(100%)"
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <PaperClipOutlined
                      className="yellow-gradiant"
                      style={{ fontSize: "28px", padding: "1rem" }}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#F4365D",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <StatisticsCard
                      subText={
                        <div>
                          <CalendarOutlined
                            style={{
                              color: "white",
                              margin: "auto",
                            }}
                          />
                          <Text
                            type="disabled"
                            style={{
                              margin: "auto",
                              color: "white",
                              marginLeft: "3px",
                            }}
                          ></Text>
                        </div>
                      }
                      title="Daily Recipe"
                      value={daily}
                      suffix={props.client ? "LD" : "TND"}
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <PaperClipOutlined
                      className="yellow-gradiant"
                      style={{ fontSize: "28px", padding: "1rem" }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} lg={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#1890FF",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <StatisticsCard
                      subText={
                        <div>
                          <CalendarOutlined
                            style={{
                              color: "white",
                              margin: "auto",
                            }}
                          />
                          <Text
                            type="disabled"
                            style={{
                              margin: "auto",
                              color: "white",
                              marginLeft: "3px",
                            }}
                          >
                            {stat.revenu != undefined ? (
                              formatDate(stat.updatedAt)
                            ) : (
                              <Spin indicator={antIcon} />
                            )}
                          </Text>
                        </div>
                      }
                      title="Income"
                      value={
                        revenue != undefined ? revenue.toFixed(2) : "loading..."
                      }
                      suffix={"TND"}
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <DollarOutlined
                      className="blue-gradiant"
                      style={{ fontSize: "28px", padding: "1rem" }}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "rgb(51, 203, 85)",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <StatisticsCard
                      subText={
                        <div>
                          <CalendarOutlined
                            style={{
                              color: "white",
                              margin: "auto",
                            }}
                          />
                          <Text
                            type="disabled"
                            style={{
                              margin: "auto",
                              color: "white",
                              marginLeft: "3px",
                            }}
                          >
                            {stat.count != undefined ? (
                              formatDate(stat.updatedAt)
                            ) : (
                              <Spin indicator={antIcon} />
                            )}
                          </Text>
                        </div>
                      }
                      title={"Success"}
                      value={stat.success}
                      suffix={
                        "(" +
                        Math.round(
                          (Number(stat.success) * 100) / Number(stat.count)
                        ) +
                        "%)"
                      }
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <CheckCircleOutlined
                      className="green-gradiant"
                      style={{ fontSize: "28px", padding: "1rem" }}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "rgb(227, 94, 107)",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <StatisticsCard
                      subText={
                        <div>
                          <CalendarOutlined
                            style={{
                              color: "white",
                              margin: "auto",
                            }}
                          />
                          <Text
                            type="disabled"
                            style={{
                              margin: "auto",
                              color: "white",
                              marginLeft: "3px",
                            }}
                          >
                            {/* {new Date(purchases[1].creation_date)} */}
                            {/* {new Date(initialDate)} */}
                            {stat.revenu != undefined ? (
                              formatDate(stat.updatedAt)
                            ) : (
                              <Spin indicator={antIcon} />
                            )}
                          </Text>
                        </div>
                      }
                      title={"Failed"}
                      value={fail}
                      suffix={
                        "(" +
                        Math.round((Number(fail) * 100) / Number(total)) +
                        "%)"
                      }
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <ExclamationCircleOutlined
                      className="red-gradiant"
                      style={{ fontSize: "28px", padding: "1rem" }}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={5}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "rgb(255, 205, 54)",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <StatisticsCard
                      subText={
                        <div>
                          <CalendarOutlined
                            style={{
                              color: "white",
                              margin: "auto",
                            }}
                          />
                          <Text
                            type="disabled"
                            style={{
                              margin: "auto",
                              color: "white",
                              marginLeft: "3px",
                            }}
                          >
                            {stat.income != undefined ? (
                              formatDate(stat.updatedAt)
                            ) : (
                              <Spin indicator={antIcon} />
                            )}
                          </Text>
                        </div>
                      }
                      title="All"
                      value={stat.count}
                      suffix="(100%)"
                    />
                  </div>
                  <div style={{ margin: "auto" }}>
                    <PaperClipOutlined
                      className="yellow-gradiant"
                      style={{ fontSize: "28px", padding: "1rem" }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <Spin
          indicator={
            <div
              className="progress"
              style={{
                position: "absolute",
                top: "25%",
                left: "50%",
              }}
            >
              <div>{antIcon}</div>
            </div>
          }
          size="small"
        />
      )}
    </div>
  );
};

export default GeneralCards;
