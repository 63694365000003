import React, { useEffect, useState } from "react";
import { Divider, Card, Badge, InputNumber, Button, Modal } from "antd";
import axios from "axios";

//import Graph from "./GraphOMG";
import Table from "./TableOMG";
import Wallet from "../Wallet/Wallet";

const UmbrellaControllerPage = () => {
  const [Offers, setOffers] = useState([]);
  const [voucher, setVouchers] = useState([]);
  const [voucherToAdd, setVouchersToAdd] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sku, setSku] = useState("");
  const [voucherName, setVoucherName] = useState("");
  const [voucherPrice, setVoucherPrice] = useState(0);

  const [wallet, setWallet] = useState({});

  useEffect(() => {
    getWallet();
    getOffers();
    getVouchers();
  }, []);
  const getOffers = async () => {
    const offers = await axios.post("/api/gamecode/offers");

    let off = offers.data.offers;
    off.sort(function (a, b) {
      if (a.price.amount < b.price.amount) {
        return -1;
      }
      if (a.price.amount > b.price.amount) {
        return 1;
      }
      return 0;
    });
    setOffers(off);
  };

  function onChange(value) {
    console.log("changed", value);
    setVouchersToAdd(value);
  }

  function showModal(name, sku, price) {
    setSku(sku);
    setVoucherName(name);
    setVoucherPrice(price);
    setIsModalVisible(true);
  }

  const handleOk = async () => {
    //Purchase bulk vouchers
    const body = {
      sku: sku,
      qt: voucherToAdd,
    };
    const purchaseVouchers = await axios.post(
      "/api/gamecode/bullk/placeorder",
      body
    );
    //console.log("vouchers ", purchaseVouchers.data);
    setVouchersToAdd(1);
    await getVouchers();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getVouchers = async () => {
    const vouchers = await axios.get("/api/umbrella/vouchers/status");
    setVouchers(vouchers.data.vouchers.response);
  };

  const getWallet = async () => {
    const body = {
      retailer: "umbrella",
    };
    const wallet = await axios.post("/api/gshop/getWallet", body);
    console.log("wallet", wallet.data);
    setWallet(wallet.data.wallet);
  };
  return (
    <div>
      <div style={{ maxWidth: "100%" }}>
        {/* <div style={{
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <Table plotName="OMG_INDEXES" />
                </div> */}

        <div>
          <Divider orientation="center">Umbrella vouchers</Divider>

          {/* {voucher.map((value, key) => (
            <div style={{ margin: "1rem" }}>
              <Card title={value.name} bordered={false}>
                <div>
                  quantity left : <b>{value.count}</b> */}

          {/* <Badge
                    dot={value.active}
                    status={value.active ? "success" : "error "}
                    size="small"
                  >
                    <span style={{ marginLeft: "8rem" }}>
                      <b>Product status </b>
                    </span>
                  </Badge>
                  <span style={{ marginLeft: "5rem" }}>
                    add vouchers from EZPIN :
                  </span>
                  <span style={{ marginLeft: "1rem" }}>
                    <InputNumber
                      min={1}
                      max={1000}
                      defaultValue={1}
                      onChange={onChange}
                    />
                  </span>
                  <span>
                    <Button
                      type="primary"
                      onClick={() =>
                        showModal(value.name, value.sku, value.price)
                      }
                    >
                      Submit purchase
                    </Button>
                  </span> */}
          {/* </div>
              </Card>
            </div>
          ))} */}

          <Wallet wallet={wallet} />
          <Modal
            title={" purchase"}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>
              Are you sure to purchase{" "}
              <b>
                {voucherToAdd} x {voucherName}{" "}
              </b>{" "}
              ?
            </p>

            <p>
              Total price :<b> {voucherPrice * voucherToAdd} </b> $
            </p>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default UmbrellaControllerPage;
