import { Divider } from "antd";
import axios from "axios";
import React, { useState, useContext } from "react";
import { List, Space } from "antd";
import { MessageOutlined, LikeOutlined, StarOutlined } from "@ant-design/icons";
import GamesContext from "../../context/GamesContext";

const listData = [];
for (let i = 0; i < 23; i++) {
  listData.push({
    href: "https://ant.design",
    title: `ant design part ${i}`,
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    description:
      "Ant Design, a design language for background applications, is refined by Ant UED Team.",
    content:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
  });
}

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);
const GamesHorizontalList = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const gamesContext = useContext(GamesContext);
  const getGamesList = () => {
    axios.get("/api/games").then((res) => {
      setGames(res.data);
      setLoading(false);
    });
  };
  return (
    <div>
      <Divider orientation="center">Games</Divider>
      <List
        itemLayout="vertical"
        size="small"
        dataSource={gamesContext.games}
        renderItem={(item) => (
          <List.Item
            className="games-list-item"
            key={item.Name}
            actions={[
              <IconText
                icon={StarOutlined}
                text="156"
                key="list-vertical-star-o"
              />,
              <IconText
                icon={LikeOutlined}
                text="156"
                key="list-vertical-like-o"
              />,
              <IconText
                icon={MessageOutlined}
                text="2"
                key="list-vertical-message"
              />,
            ]}
            extra={
              <img
                width={272}
                alt="logo"
                src={`http://boardgain.io${item.Logo}`}
              />
            }
          >
            <List.Item.Meta
              title={<a href={item.Name}>{item.Name}</a>}
              description={item.Description}
            />
            {/* {item.Description} */}
          </List.Item>
        )}
      />
    </div>
  );
};

export default GamesHorizontalList;
