import React from "react";
import CustomEditableTable from "../utils/CustomEditableTable";

const Client = () => {
  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <div className="header-secondary">Clients List</div>
      <div>
        <CustomEditableTable />
      </div>
    </div>
  );
};

export default Client;
