import React, { useEffect, useState } from "react";
import axios from "axios";
import OffersList from "./OffersList";

const OffersDetails = (props) => {
  const [offers, setOffers] = useState(null);
  const [loading, setLoading] = useState(true);
  const getOffersByGameName = () => {
    axios.get("/api/offers/" + props.gameName).then((res) => {
      setOffers(res.data);
      //console.log(res.data)
    });
  };
  useEffect(() => {
    getOffersByGameName();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [offers]);

  return <div>{!loading ? <OffersList data={offers} /> : null}</div>;
};

export default OffersDetails;
