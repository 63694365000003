import React, { Component } from "react";
import axios from "axios";
import { List, Avatar, Divider, Switch, message, Spin, BackTop } from "antd";
import GamePurchaseItem from "./GamePurchaseItem";

class GamePurchases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offers: null,
      avatar: "",
      loading: true,
      selectedGame: null,
      gameDisplay: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedGame !== this.props.selectedGame) {
      // this.setState(
      //   {
      //     loading: true,
      //   },
      //   () => {
      //     axios
      //       .get("/api/purchase/" + this.props.selectedGame.Name)
      //       .then((res) => {
      //         this.setState(
      //           {
      //             offers: res.data,
      //             selectedGame: this.props.selectedGame,
      //             gameDisplay: this.props.selectedGame.display,
      //             avatar:
      //               "https://boardgain.xyz" + this.props.selectedGame.Logo,
      //           },
      //           () => {
      //             this.setState({
      //               loading: false,
      //             });
      //           }
      //         );
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //       });
      //   }
      // );
    }
  }
  onChange = (checked) => {
    // var i = checked ? 1 : 0;
    // const newGame = {
    //   _id: this.state.selectedGame._id,
    //   display: i,
    // };
    // axios
    //   .patch("/api/games/update", newGame)
    //   .then((res) => {
    //     console.log(res.data);
    //     this.setState({
    //       gameDisplay: checked,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  confirm(e) {
    console.log(e);
    message.success("Click on Yes");
  }

  cancel(e) {
    console.log(e);
    message.error("Click on No");
  }
  render() {
    return (
      <div>
        {!this.props.selectedGame ? (
          <div style={{ textAlign: "center" }}>
            Please select a game to view the different statistics.
          </div>
        ) : (
          <div>
            <Divider orientation="center">
              Manage {this.props.selectedGame.Name}
            </Divider>
            <div style={{ textAlign: "center" }}>
              <span style={{ marginRight: "1rem" }}>Display Game</span>
              <Switch
                checked={this.state.gameDisplay}
                onChange={this.onChange}
              />
              {/* <Popconfirm
                title="Are you sure to display this game?"
                onConfirm={this.confirm()}
                onCancel={this.cancel()}
                okText="Yes"
                cancelText="No"
              >
                <Switch
                  checked={this.state.gameDisplay}
                  onChange={'='}
                />
              </Popconfirm> */}
            </div>

            <Divider orientation="center">Recent Purchases</Divider>
            <div className="purchases-container">
              {!this.state.loading ? (
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.offers}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={this.state.avatar} />}
                        title={<a href="/">{item.purchase_name}</a>}
                        description={<GamePurchaseItem purchase={item} />}
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <Spin
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "5%",
                  }}
                  size="large"
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default GamePurchases;
