import React, {useState} from 'react';
import {InputNumber} from "antd";
import PurchaseConfirm from "./products/purchaseConfirm";

const QuantityInput = (props) => {
    const [quantitySelected, setQuantitySelected] = useState(1);
    return (
        <div>
            <InputNumber
                name="quantity"
                onChange={(value) => {
                    setQuantitySelected(value)
                }}
                min={1}
                max={10}
                defaultValue={1}
            />
            <PurchaseConfirm
                sku={props.sku}
                quantity={quantitySelected}
                user={props.user}
            />
        </div>
    );
};

export default QuantityInput;