import React, { useEffect, useState } from "react";
import { Button, Tooltip, message } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";

const ResendButton = (props) => {
  const [visible, setVisible] = useState(false);
  const [purchase, setPurchase] = useState({});
  const [loading, setloading] = useState(false);
  const getPurchase = () => {
    if (props.retailer == "orange") {
      const body = {
        id: props.id,
      };
      axios.post("/api/orange/get/purchaseById", body).then((result) => {
        setPurchase(result.data.purchase);
        setloading(true);
      });
    } else {
      console.log("id ", props.id);
      const body = {
        _id: props.id,
      };
      axios.post("/api/purchase/gamecode/purchasebyid", body).then((result) => {
        setPurchase(result.data);
        setloading(true);
      });
    }
  };

  const sendVoucher = async () => {
    if (props.retailer == "orange") {
      const body = {
        id: purchase.short_id,
      };
      await axios.post("/api/orange/refund", body).then((res) => {
        console.log("res", res.data);
        message.success(
          "an sms was sent to ",
          purchase.purchase_info.phone_number
        );
        setVisible(false);
      });
    } else {
      const body = {
        short_id: purchase.short_id,
      };
      await axios.post("/api/gamecode/sendvoucher", body).then((res) => {
        console.log("res", res.data);
        message.success(
          "an sms was sent to ",
          purchase.purchase_info.phone_number
        );
        setVisible(false);
      });
    }
  };
  return (
    <div>
      <Tooltip title="Purchase Details">
        <Button
          // style={{ width: "100%" }}
          type="primary"
          shape="circle"
          icon={<MessageOutlined />}
          onClick={() => {
            getPurchase();
            setVisible(true);
          }}
        >
          {/* Details */}
        </Button>
      </Tooltip>
      <Modal
        width={500}
        title={`Order Details : ${purchase.short_id}`}
        visible={visible}
        onOk={() => {
          //setVisible(false);
          sendVoucher();
        }}
        onCancel={() => {
          setVisible(false);
        }}
        okText="Confirm"
        cancelText="Cancel"
      >
        {loading ? (
          <div style={{ textAlign: "center", fontSize: "15px" }}>
            Do you confirm to resend SMS to
            <b> "{purchase.user_info.phone_number}"</b> ?
          </div>
        ) : (
          <div>loading...</div>
        )}
      </Modal>
    </div>
  );
};

export default ResendButton;
