import React, { useContext, useState, useEffect, useRef } from "react";

import { Table, Input, Button, Popconfirm, Form } from "antd";

import {UserAddOutlined} from "@ant-design/icons";

import axios from "axios";

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class CustomEditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Country",
        dataIndex: "country",
        width: "30%",
        editable: true,
      },
      {
        title: "Region",
        dataIndex: "region",
        editable: true,
      },
      {
        title: "Abbreviation",
        dataIndex: "abbreviation",
        editable: true,
      },
      {
        title: "operation",
        dataIndex: "operation",
        render: (text, record) => {
          if (this.state.dataSource.length >= 1) {
            return (
              <Popconfirm
                title="Are you sure to delete?"
                onConfirm={() => this.handleDelete(record._id)}
              >
                <a href="/">Delete</a>
              </Popconfirm>
            );
          }
        },
      },
    ];
    this.state = {
      dataSource: [],
      count: 2,
    };
  }

  componentDidMount() {
    this.getClientList();
  }

  getClientList() {
    axios
      .get("/api/clients/")
      .then((res) => {
        this.setState({
          dataSource: res.data,
          count: res.data.length,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleDelete = (id) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item._id !== id),
    });
    axios
      .delete("/api/clients/delete/" + id)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  handleAdd = () => {
    const client = {
      country: "New Client",
      abbreviation: "CL",
      region: "NA",
    };
    axios.post("/api/clients/create", client).then((res) => {
      this.getClientList();
    });
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row._id === item._id);
    const item = newData[index];
    console.log(row);
    axios.patch("/api/clients/update", row).then((res) => {
      newData.splice(index, 1, { ...item, ...row });
      this.setState({
        dataSource: newData,
      });
    });
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <Button
            shape="round"
            onClick={this.handleAdd}
            type="primary"
            style={{
              marginBottom: 16,
            }}
            icon={<UserAddOutlined />}
          >
            Create a client
          </Button>
        </div>
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns}
        />
      </div>
    );
  }
}

export default CustomEditableTable;
