import axios from "axios";

const service = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "/api/"
      : `http://${window.location.hostname}:5000/api/`,

  withCredentials: true,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  },
});

const errHandler = (err) => {
  if (err.response && err.response.data) {
    throw err.response.data.message;
  }
  throw err;
};

export default {
  service: service,
  getAll() {
    return service
      .get("purchase/")
      .then((res) => res.data)
      .catch(errHandler);
  },
  getByRetailer(retailer) {
    return service
      .post("purchase/mgp/allByRetailer", retailer)
      .then((res) => res.data)
      .catch(errHandler);
  },
  getOrange() {
    return service
      .get("purchase/orange")
      .then((res) => res.data)
      .catch(errHandler);
  },
  getSobflous() {
    return service
      .get("purchase/sobflous")
      .then((res) => res.data)
      .catch(errHandler);
  },
  getByMarket(market) {
    return service
      .get("purchase/", market)
      .then((res) => res.data)
      .catch(errHandler);
  },
  getRecent() {
    return service
      .get("purchase/get/recent")
      .then((res) => res.data)
      .catch(errHandler);
  },
  Deliver(body) {
    return service
      .post("purchase/deliver", body)
      .then((res) => res.data)
      .catch(errHandler);
  },
  getCount(body) {
    return service
      .post("mygameplace/get-by-count", body)
      .then((res) => res.data)
      .catch(errHandler);
  },
};
