import { Button, InputNumber, Spin, Table } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import PurchaseConfirm from "./products/purchaseConfirm";
import axios from "axios";
import PurchasesHistoryItem from "./purchases/purchasesHistoryItem";
import UserContext from "../../context/UserContext";
import QuantityInput from "./QuantityInput";
const Shop = () => {
  const [data, setData] = useState([]);
  const userContext = useContext(UserContext);
  const [fetching, setFetching] = useState(true);
  const antIcon = <LoadingOutlined spin />;
  const [cart, setCart] = useState([]);
  const [quantitySelected, setQuantitySelected] = useState(1);
  const fetchData = async () => {
    const response = await axios.get("/api/products");
    setData(response.data);
    setFetching(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Pack",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "In Stock",
      dataIndex: "stock",
      key: "stock",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => <div>{price.price}</div>,
    },
    {
      title: "Action",
      dataIndex: "sku",
      key: "sku",
      render: (sku) => (<QuantityInput sku={sku} user={userContext.userData.user}/>),
    },
  ];

  return (
    <div>
      {fetching ? (
        <div style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin indicator={antIcon} />
            <div>Fetching data</div>
          </div>
        </div>
      ) : (
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <div className="header-secondary">Shop</div>

          <div>
            <div style={{ padding: "1rem" }}>
              <div className="header-secondary" style={{ textAlign: "left" }}>
                PUBG Mobile{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src="https://mygameplace.boardgain.xyz/files/games/icons/PUBG Mobile.png"
                    width="250px"
                    className="box-shadow"
                  />
                </div>
                <div style={{ padding: "1rem", width: "100%" }}>
                  <Table dataSource={data} columns={columns} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="header-secondary" style={{ textAlign: "left" }}>
              History{" "}
            </div>
            <div>
              <PurchasesHistoryItem />
              <PurchasesHistoryItem />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Shop;
