import React, { useState } from "react";
import { Modal, Button } from "antd";
import PatchEditor from "./PatchEditor";
import { FileAddOutlined } from "@ant-design/icons";

const PatchCreator = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => setVisible(true)}
          type="primary"
          shape="round"
          icon={<FileAddOutlined />}
        >
          New Patch
        </Button>
      </div>

      <Modal
        title="New Patch Note"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={"99%"}
        footer={null}
      >
        <PatchEditor
          setVisibility={setVisible}
          refresh={props.refresh}
          app={props.app}
        />
      </Modal>
    </div>
  );
};

export default PatchCreator;
