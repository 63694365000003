import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Divider, Spin, Button, Card, Badge, InputNumber } from "antd";

import { DatePicker, Checkbox, Input, message, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import StatisticsCard from "../utils/data/StatisticsCard";
import {
  DollarOutlined,
  LoadingOutlined,
  DownloadOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import Modal from "antd/lib/modal/Modal";
import ResendButton from "../utils/functional/ResendButton";

import { makeStyles } from "@material-ui/core/styles";
import { MessageOutlined } from "@ant-design/icons";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableItem from "../utils/purchases Table/TableItem";

import {
  FormControl,
  Grid,
  InputLabel,
  InputAdornment,
  FilledInput,
  TextField,
} from "@material-ui/core";

const { RangePicker } = DatePicker;

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});
const PurchaseClient = (props) => {
  const [pageName, setPageName] = useState("");
  const [purchases, setPurchases] = useState(null);
  const [purchasesToDisplay, setPurchasestoDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [revenue, setRevenue] = useState(0);
  const [daily, setDaily] = useState(0);
  const [downloadModal, setdownloadModal] = useState(false);
  const [dataToDownLoad, setdataToDownLoad] = useState({});
  const [fileName, setFileName] = useState("");
  const [successCheck, setScucessCheck] = useState(false);
  const [successPurchases, setSuccessPurchases] = useState([]);
  const [phoneNumber, setphoneNumber] = useState("");
  const [selectedRowKeys, setselectedRowKeys] = useState(1);
  const [voucher, setVouchers] = useState([]);
  const [games, setGamess] = useState([]);
  //Table variables
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pagination, setPagination] = useState(true);
  // update prices
  const [openUpdateDialog, OpenUpdateDialog] = useState(false);
  const [offerToUpdate, setOfferToUpdate] = useState({});
  const [newOfferPrice, setNewOfferPrice] = useState("");

  const columns = [
    { id: "short_id", label: "ID", minWidth: "100%", align: "center" },
    { id: "name", label: "Name", minWidth: "100%" },
    { id: "status", label: "Status", minWidth: "100%", align: "center" },
    {
      id: "paymentStatus",
      label: "payment Status",
      minWidth: "100%",
      align: "center",
    },
    {
      id: "phoneNumber",
      label: "phone number",
      minWidth: 170,
      align: "center",
    },
    {
      id: "date",
      label: "Date ",
      minWidth: 170,
      align: "center",
    },
    {
      id: "amount",
      label: "Amount ( $ )",
      minWidth: "100%",
      align: "center",
    },
    {
      id: "endUserAmount",
      label: "End User Amount ( DL )",
      minWidth: "100%",
      align: "center",
    },
    {
      id: "oldBalance",
      label: "Old Balance ( DL )",
      minWidth: "100%",
      align: "center",
    },
    {
      id: "newBalance",
      label: "New Balance ( DL )",
      minWidth: "100%",
      align: "center",
    },
  ];

  function createData(
    _id,
    short_id,
    name,
    status,
    paymentStatus,
    date,
    phoneNumber,
    endUserAmount,
    amount,
    sku,
    originalPrice,
    provider,
    oldBalance,
    newBalance
  ) {
    return {
      _id,
      short_id,
      name,
      status,
      paymentStatus,
      date,
      phoneNumber,
      endUserAmount,
      amount,
      sku,
      originalPrice,
      provider,
      oldBalance,
      newBalance,
    };
  }

  const handleChangePage = async (event, newPage) => {
    if (newPage > maxPage && pagination) {
      await getPurchases();
      setPage(newPage);

      setMaxPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  // const handleChangeRowsPerPage = (event) => {
  //   console.log("row page", event.target.value);
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const headers = [
    { label: "Offer Name", key: "offerName" },
    { label: "Status", key: "status" },
    { label: "Payment Status", key: "paymentStatus" },
    { label: "Purchase Date", key: "purchaseDate" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Amount (DL)", key: "amount" },
  ];

  const Adminheaders = [
    { label: "Offer Name", key: "offerName" },
    { label: "Provider", key: "provider" },

    { label: "Retailer", key: "retailer" },
    { label: "Selling amount (DL)", key: "endUserAmount" },
    { label: "Selling Amount ($)", key: "selling" },
    { label: "Original Price", key: "originalPrice" },
    { label: "Purchase Date", key: "purchaseDate" },
  ];

  const filterArray = () => {
    var data = [];
    dataToDisplay.forEach((element) => {
      let obj = {
        offerName: element.name,
        status: element.status,
        paymentStatus: element.paymentStatus,
        purchaseDate: formatDate(element.date),
        phoneNumber: element.phoneNumber.slice(3, 12),
        amount: element.endUserAmount,
      };

      data.push(obj);
    });

    setdataToDownLoad(data);
  };

  const filterArrayAdmin = () => {
    var data = [];

    dataToDisplay.forEach((element) => {
      let obj = {
        offerName: element.name,
        provider: element.provider,
        retailer: "Lybiana",
        purchaseDate: formatDate(element.date),
        endUserAmount: element.endUserAmount,
        selling: element.amount,
        originalPrice: element.originalPrice,
        // originalPrice:
        //   element.purchase_info.sku == "452"
        //     ? "0.99 $ "
        //     : element.purchase_info.sku == "453"
        //     ? "4.99  $ "
        //     : element.purchase_info.price.amount + " DT",
      };

      data.push(obj);
    });
    setdataToDownLoad(data);
  };

  const getPurchases = async () => {
    const body = {
      skip: data.length > 0 ? data[data.length - 1]._id : "",
      count: 10,
    };

    const Newdata = await Axios.post(
      "/api/purchase/get/gamecodePurchasesbycount",
      body
    );

    var table = data;
    Newdata.data.forEach((element) => {
      table.push(
        createData(
          element._id,
          element.short_id,
          element.purchase_info.description,
          element.purchase_info.status,
          element.retailer_response.status,
          formatDate(element.creation_date),
          element.user_info.phone_number,

          element.purchase_info.price.amount / 1000,
          element.purchase_info.price.enduser_amount,
          element.purchase_info.sku,
          element.purchase_info.price.original_amount,
          element.purchase_info.provider,
          element.retailer_response.old_balance == "N/A"
            ? "N/A"
            : (element.retailer_response.old_balance / 100000).toFixed(3),
          element.retailer_response.new_balance == "N/A"
            ? "N/A"
            : (element.retailer_response.new_balance / 100000).toFixed(3)
        )
      );
    });

    setDataToDisplay(table);
    setData(table);
    setLoading(false);
    getPurchasesRevenue(table);
  };

  const getVouchers = async () => {
    const body = { stock: "iaccess" };

    const games = await Axios.post("/api/gamecode/games");
    setGamess(games.data.games);
    const vouchers = await Axios.post("/api/gamecode/vouchers/status", body);

    setVouchers(vouchers.data.vouchers.result);
  };

  useEffect(() => {
    getVouchers();
  }, []);

  useEffect(() => {
    getPurchases();
  }, [props.purchases]);

  // useEffect(() => {
  //   getPurchasesRevenue();
  // }, [data]);

  // useEffect(() => {
  //   console.log("user", props.user.role);
  //   if (props.retailer != "umbrella") {
  //     getPurchasesRevenue(purchasesToDisplay);
  //   }
  // }, [purchasesToDisplay]);

  const handleDatePicker = async (date, dateString) => {
    var newData = [];
    setphoneNumber("");
    if (date === null) {
      window.location.reload();
      setPagination(true);
      setDataToDisplay(data);
      getPurchasesRevenue(data);

      setScucessCheck(false);
    } else {
      setPagination(false);
      setScucessCheck(false);
      var startDate = new Date(dateString[0]);
      var endDate = new Date(dateString[1]);
      const body = {
        start: startDate,
        end: endDate,
      };

      const purchasesByDate = await Axios.post(
        "/api/gamecode/lybiana/purchases/date",
        body
      );

      var table = [];
      purchasesByDate.data.purchases.forEach((element) => {
        table.push(
          createData(
            element._id,
            element.short_id,
            element.purchase_info.description,
            element.purchase_info.status,
            element.retailer_response.status,
            formatDate(element.creation_date),
            element.user_info.phone_number,

            element.purchase_info.price.amount / 1000,
            element.purchase_info.price.enduser_amount,
            element.purchase_info.sku,
            element.purchase_info.price.original_amount,
            element.purchase_info.provider,
            element.retailer_response.old_balance == "N/A"
              ? "N/A"
              : (element.retailer_response.old_balance / 100000).toFixed(3),
            element.retailer_response.new_balance == "N/A"
              ? "N/A"
              : (element.retailer_response.new_balance / 100000).toFixed(3)
          )
        );
      });

      setDataToDisplay(table);
      getPurchasesRevenue(table);
    }
  };

  const getPurchasesRevenue = (purchases) => {
    var total = 0;
    var daily = 0;

    purchases.forEach((purchase) => {
      if (
        purchase.status.toUpperCase() == "FULFILLED" ||
        purchase.status.toUpperCase() == "REFUNDED"
      ) {
        total += parseFloat(purchase.endUserAmount);
        if (
          new Date(purchase.creation_date).getDate() == new Date().getDate() &&
          new Date(purchase.creation_date).getMonth() == new Date().getMonth()
        ) {
          daily += parseInt(purchase.purchase_info.price.amount);
        }
      }
    });
    if (props.retailer == "orange") {
      setRevenue(total);
      setDaily(daily);
    } else {
      setRevenue(total);
      // setDaily(daily);
    }

    //return total;
  };

  const formatDate = (d) => {
    var date = new Date(d);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      "  " +
      strTime
    );
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  function onChangeSuccess(e) {
    setScucessCheck(e.target.checked);

    if (e.target.checked) {
      var sucessData = [];
      setPage(0);

      sucessData = dataToDisplay.filter(
        (value) =>
          value.paymentStatus == "FULFILLED" ||
          value.paymentStatus == "REFUNDED"
      );
      setDataToDisplay(sucessData);
    } else {
      setDataToDisplay(data);
    }
  }
  async function filterbyMSDI(e) {
    setScucessCheck(false);
    setPagination(true);
    setphoneNumber(e.target.value);
    if (e.target.value === "") {
      setPage(0);
      getPurchasesRevenue(data);
      setDataToDisplay(data);
      setPagination(true);
    } else if (e.target.value.length == 12) {
      const body = {
        phoneNumber: e.target.value,
      };
      var newData = [];
      const purchasesByPhone = await Axios.post(
        "/api/gamecode/lybiana/purchases/phonenumber",
        body
      );
      setPagination(false);
      setPage(0);

      purchasesByPhone.data.purchases.purchases.forEach((element) => {
        newData.push(
          createData(
            element._id,
            element.short_id,
            element.purchase_info.description,
            element.purchase_info.status,
            element.retailer_response.status,
            formatDate(element.creation_date),
            element.user_info.phone_number,

            element.purchase_info.price.amount / 1000,
            element.purchase_info.price.enduser_amount,
            element.purchase_info.sku,
            element.purchase_info.price.original_amount,
            element.purchase_info.provider,
            element.retailer_response.old_balance == "N/A"
              ? "N/A"
              : (element.retailer_response.old_balance / 100000).toFixed(3),
            element.retailer_response.new_balance == "N/A"
              ? "N/A"
              : (element.retailer_response.new_balance / 100000).toFixed(3)
          )
        );
      });
      getPurchasesRevenue(newData);

      setDataToDisplay(newData);
    }
  }

  const showModal = () => {
    if (props.user.role == "super") {
      console.log("extract data");
      setdownloadModal(true);
      filterArrayAdmin();
    } else {
      setdownloadModal(true);
      filterArray();
    }
  };

  const handleOk = () => {
    setdownloadModal(false);
  };

  const success = () => {
    message.success("your file is downloading");
  };

  const onChangeOfferPrice = (value) => {
    console.log("changed", value);
    setNewOfferPrice(value);
  };

  const UpdateOfferPrice = async () => {
    const body = {
      offerId: offerToUpdate.offer_id,
      newPrice: newOfferPrice,
    };
    const updateOfferPrice = await Axios.post(
      "/api/gamecode/offers/updateOfferPrice",
      body
    );

    if (updateOfferPrice.status == 200) {
      getVouchers();
    }
  };

  return (
    <div className="site-layout-background" style={{ minHeight: 360 }}>
      <Divider orientation="center">Purchases History</Divider>
      <div
        style={{
          width: "100%",
          minHeight: "100px",
          position: "relative",
          marginBottom: "2rem",
        }}
      >
        <div
          style={{
            marginTop: "1rem",
            marginRight: "1rem",
            position: "absolute",
            right: "0",
          }}
        >
          <div
            // className="box-shadow"
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#1890FF",
              borderRadius: "8px",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "auto" }}>
              <StatisticsCard
                subText={
                  <div>
                    <CalendarOutlined
                      style={{
                        color: "white",
                        marginBottom: "auto",
                      }}
                    />
                    <Text
                      type="disabled"
                      style={{
                        margin: "auto",
                        color: "white",
                        marginLeft: "3px",
                      }}
                    >
                      since :{" "}
                      {dataToDisplay[dataToDisplay.length - 1] != undefined ? (
                        formatDate(dataToDisplay[dataToDisplay.length - 1].date)
                      ) : (
                        <Spin indicator={antIcon} />
                      )}
                    </Text>
                  </div>
                }
                title="Total amount"
                value={revenue.toFixed(2)}
                suffix={props.retailer != "orange" ? "LD" : "TND"}
              />
            </div>

            <div>
              <DollarOutlined
                // className="blue-gradiant"
                style={{
                  fontSize: "28px",
                  padding: "1rem",
                  color: "#87D068",
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: "0px",
            paddingLeft: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                marginLeft: "7px",
                marginRight: "7px",
                fontWeight: "bold",
              }}
            >
              Pick By Date
            </span>
            {/* <DatePicker onChange={handleDatePicker} /> */}
            <RangePicker onChange={handleDatePicker} />
            <span
              style={{
                marginLeft: "7px",
                marginRight: "7px",
                fontWeight: "bold",
              }}
            >
              <Checkbox onChange={onChangeSuccess} checked={successCheck}>
                Select success
              </Checkbox>
            </span>
            <span>
              <Input.Group compact>
                <Input.Search
                  allowClear
                  style={{ width: "100%" }}
                  defaultValue="26888888"
                  onChange={filterbyMSDI}
                  value={phoneNumber}
                />
              </Input.Group>
            </span>
          </div>
        </div>
      </div>
      {!loading ? (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell>action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataToDisplay
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <TableItem value={value} color="red" />
                            </TableCell>
                          );
                        })}

                        <TableCell>
                          <ResendButton id={row._id} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            //rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={10 + dataToDisplay.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : null}
      <span style={{ textAlign: "right" }}>
        <Button type="primary" onClick={showModal}>
          Download panel
        </Button>
        <Modal
          title="Download panel"
          visible={downloadModal}
          onOk={handleOk}
          onCancel={handleOk}
        >
          <div style={{ margin: "1rem" }}>
            <span>Please choose a file name before the download</span>

            <Input
              onChange={(e) => {
                setFileName(e.target.value);
              }}
              value={fileName}
              placeholder="file name"
            />
          </div>
          <CSVLink
            filename={fileName + ".csv"}
            data={dataToDownLoad}
            headers={props.user.role == "super" ? Adminheaders : headers}
            separator={";"}
            //enclosingCharacter={`'`}
          >
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              icon={<DownloadOutlined />}
              onClick={success}
            >
              Download CSV
            </Button>
          </CSVLink>
        </Modal>
      </span>

      <Divider orientation="center">Game Code Stock</Divider>

      <Grid
        container
        sm={12}
        md={12}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        {games.map((value, key) => (
          <Grid
            item
            sm={6}
            xs={6}
            md={4}
            style={{ margin: "1rem" }}
            // className="col-lg-4"
            onClick={() => {
              console.log("clicked", value._id);
            }}
          >
            <Card
              title={value.Name}
              cover={
                <img
                  style={{ width: "200px" }}
                  src={"https://gamecode.ly" + value.Announcement_pic}
                />
              }
            >
              <div>
                <Badge dot={value.available} color="green">
                  <span style={{ marginLeft: "8rem" }}>
                    <b>Product status </b>
                  </span>
                </Badge>
              </div>

              <Divider />
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Product</TableCell>

                    <TableCell align="left">Quantity</TableCell>
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="left">Displayed</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                {voucher.map((offer) => {
                  if (offer.game_id == value._id)
                    return (
                      <TableRow>
                        <TableCell align="left">{offer.name}</TableCell>

                        <TableCell align="center">{offer.count}</TableCell>
                        <TableCell align="left">{offer.price}</TableCell>
                        <TableCell align="left">
                          {offer.active ? "available" : "not available"}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Purchase Details">
                            <Button
                              // style={{ width: "100%" }}
                              type="primary"
                              shape="circle"
                              icon={<MessageOutlined />}
                              onClick={() => {
                                //   getPurchase();
                                setOfferToUpdate(offer);
                                console.log("offer selected ", offer.price);
                                OpenUpdateDialog(true);
                              }}
                            ></Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                })}
              </Table>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal
        width={500}
        title={`Order Details : ${offerToUpdate.name}`}
        visible={openUpdateDialog}
        onOk={() => {
          //setVisible(false);
          //  sendVoucher();
          OpenUpdateDialog(false);
          setOfferToUpdate({});
          UpdateOfferPrice();
        }}
        onCancel={() => {
          OpenUpdateDialog(false);
          OpenUpdateDialog(false);
          setOfferToUpdate({});
        }}
        okText="Update Price"
        cancelText="Cancel"
      >
        <InputNumber
          defaultValue={offerToUpdate.price}
          style={{
            width: 200,
          }}
          min="0"
          max="80000"
          step="0.001"
          onChange={onChangeOfferPrice}
        />
        {/* <TextField
          required
          id="outlined-required"
          defaultValue={offerToUpdate.price}
          onChange={onChangeOfferPrice}
        /> */}
      </Modal>
    </div>
  );
};

export default PurchaseClient;
