import { Divider, Spin } from "antd";
import React, { useEffect, useState, useContext } from "react";
import PurchaseContext from "../../context/PurchaseContext";
import "./Leaderboard.css";
import TopUsers from "./TopUsers";
import { List, Avatar } from "antd";
import { Result, Button } from "antd";
import { TrophyTwoTone, StarTwoTone, LikeTwoTone } from "@ant-design/icons";
const Leaderboard = (props) => {
  const [purchases, setPurchases] = useState([]);
  const [users, setUsers] = useState([]);
  const purchasesContext = useContext(PurchaseContext);
  const [loading, setLoading] = useState(true);
  const getAllUsers = () => {
    console.log(props.client);
    setPurchases(purchasesContext.purchases);
    let userToAdd = {
      email: "",
      amountPaid: 0,
    };
    purchasesContext.purchases.forEach((purchase) => {
      if (
        purchase.retailer === "sobflous" &&
        purchase.user_info.email !== "N/A" &&
        purchase.user_info.email !== null
      ) {
        if (
          users
            .map(function (e) {
              return e.email;
            })
            .indexOf(purchase.user_info.email.toLowerCase()) === -1
        ) {
          userToAdd = {
            email: purchase.user_info.email.toLowerCase(),
            amountPaid: 0,
          };
          users.push(userToAdd);
        }
      }
    });
    for (let index = 0, j = 0; index < users.length; index++) {
      const elementI = users[index];
      for (let j = 0; j < purchasesContext.purchases.length; j++) {
        const elementJ = purchasesContext.purchases[j];
        if (
          elementJ.retailer === "sobflous" &&
          elementJ.user_info.email !== "N/A" &&
          elementJ.user_info.email !== null
        ) {
          if (elementI.email === elementJ.user_info.email.toLowerCase()) {
            elementI.amountPaid += parseFloat(
              elementJ.purchase_info.price.amount
            );
          }
        }
      }
    }
    users.sort(function (a, b) {
      return b.amountPaid - a.amountPaid;
    });
    if (users.length > 0) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!props.client) getAllUsers();
  }, [purchasesContext.purchases]);

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Divider orientation="center">Top Buyers</Divider>
      {loading ? (
        // <div
        //   style={{
        //     display: "flex",
        //     flexDirection: "column",
        //     alignItems: "center",
        //     justifyContent: "center",
        //   }}
        // >
        //   <Spin />
        // </div>
        <Result
          status="404"
          title="404"
          subTitle="This page is coming soon."
          extra={<Button type="primary">Back Home</Button>}
        />
      ) : (
        <div>
          <TopUsers first={users[0]} second={users[1]} third={users[2]} />
          <Divider orientation="center">Leaderboard</Divider>
          <List
            itemLayout="horizontal"
            dataSource={users}
            renderItem={(item) => (
              <List.Item
                style={{
                  padding: "8px",
                }}
              >
                <List.Item.Meta
                  avatar={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          marginRight: "4px",
                        }}
                      >
                        #{users.indexOf(item) + 1}
                      </div>
                      <Avatar size="large">{item.email.charAt(0)}</Avatar>{" "}
                    </div>
                  }
                  title={
                    <a href="#">
                      {item.email.substr(0, item.email.indexOf("@"))}
                    </a>
                  }
                  description={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div>{item.email}</div>
                      <div
                        style={{
                          fontWeight: "bold",
                          textAlign: "right",
                          position: "absolute",
                          right: "0",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {item.amountPaid}TND
                        <div>
                          {users.indexOf(item) === 0 ? (
                            <TrophyTwoTone
                              twoToneColor="#FDE200"
                              style={{ fontSize: "22px" }}
                            />
                          ) : users.indexOf(item) === 1 ? (
                            <StarTwoTone
                              twoToneColor="#A2FC2B"
                              style={{ fontSize: "22px" }}
                            />
                          ) : users.indexOf(item) === 2 ? (
                            <LikeTwoTone
                              twoToneColor="#D5A4F5"
                              style={{ fontSize: "22px" }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
