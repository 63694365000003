import React, { Component } from "react";
import {
   Table,
   Tag,
   Input,
   Button,
   Space,
   Popover,
   Tooltip,
   Popconfirm,
   Spin,
} from "antd";

import Highlighter from "react-highlight-words";

import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import {
   DeleteFilled,
   DownloadOutlined,
   CheckOutlined,
   EyeOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Modal from "antd/lib/modal/Modal";
import TicketsManagement from "./TicketsManagement";
import TicketMessage from "./TicketMessage";

class TicketsTable extends Component {
   constructor(props) {
      super();
      this.state = {
         tickets: [],
         loading: true,
         viewMessage: false,
         searchText: "",
         searchedColumn: "",
      };
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.tickets !== this.props.tickets) {
         this.setState({
            tickets: this.props.tickets,
            loading: false,
         });
      }
   }
   formatDate(d) {
      var date = new Date(d);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
         date.getMonth() +
         1 +
         "/" +
         date.getDate() +
         "/" +
         date.getFullYear() +
         "  " +
         strTime
      );
   }
   getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         confirm,
         clearFilters,
      }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={(node) => {
                  this.state.searchInput = node;
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
               }
               onPressEnter={() =>
                  this.handleSearch(selectedKeys, confirm, dataIndex)
               }
               style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() =>
                     this.handleSearch(selectedKeys, confirm, dataIndex)
                  }
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
               >
                  Search
               </Button>
               <Button
                  onClick={() => this.handleReset(clearFilters)}
                  size="small"
                  style={{ width: 90 }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: (filtered) => (
         <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex]
                 .toString()
                 .toLowerCase()
                 .includes(value.toLowerCase())
            : "",
      onFilterDropdownVisibleChange: (visible) => {
         if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
         }
      },
      render: (text) =>
         this.state.searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
               searchWords={[this.state.searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ""}
            />
         ) : (
            text
         ),
   });

   handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
         searchText: selectedKeys[0],
         searchedColumn: dataIndex,
      });
   };

   handleReset = (clearFilters) => {
      clearFilters();
      this.setState({ searchText: "" });
   };

   render() {
      const columns = [
         {
            title: "Name",
            dataIndex: "ticketSender",
            key: "ticketSender",
            responsive: ["lg"],
            ...this.getColumnSearchProps("ticketSender"),
         },
         {
            title: "Email",
            dataIndex: "ticketEmail",
            key: "ticketEmail",
            ...this.getColumnSearchProps("ticketEmail"),
         },
         {
            title: "Phone",
            dataIndex: "ticketPhone",
            key: "ticketPhone",
            ...this.getColumnSearchProps("ticketPhone"),
         },
         {
            title: "Date",
            dataIndex: "ticketDate",
            key: "ticketDate",
            responsive: ["sm"],
            key: "ticketDate",
            sorter: (a, b) => a.ticketDate - b.ticketDate,
            render: (ticketDate) => {
               return <div>{this.formatDate(ticketDate)}</div>;
            },
         },
         {
            title: "Status",
            dataIndex: "ticketStatus",
            key: "ticketStatus",
            responsive: ["sm"],
            key: "ticketStatus",
            sorter: (a, b) => a.ticketDate - b.ticketDate,
            render: (ticketStatus) => {
               return ticketStatus === "resolved" ? (
                  <Tag style={{ fontWeight: "bold" }}>Resolved</Tag>
               ) : (
                  <Tag style={{ fontWeight: "bold" }}>Waiting</Tag>
               );
            },
         },
         {
            title: "Message",
            dataIndex: "ticketMessage",
            key: "ticketMessage",
            defaultSortOrder: "descend",
            responsive: ["md"],
            render: (ticketMessage) => {
               return <div>{ticketMessage}</div>;
            },
         },
         {
            title: "Actions",
            dataIndex: "_id",
            key: "actions",
            render: (_id) => {
               return <TicketsManagement _id={_id} />;
            },
         },
      ];

      return (
         <div style={{ width: "100%", padding: "1rem" }}>
            <div style={{ textAlign: "right", marginBottom: "2%" }}>
               <CSVLink filename={"purchases.csv"} data={this.state.tickets}>
                  <Button
                     type="primary"
                     shape="round"
                     icon={<DownloadOutlined />}
                  >
                     Download CSV
                  </Button>
               </CSVLink>
            </div>
            {!this.state.loading ? (
               <Table
                  columns={columns}
                  dataSource={this.state.tickets}
                  // expandRowByClick={true}
                  // expandable={{
                  //   expandedRowRender: (record, index) => {
                  //     console.log(index);
                  //     return <p style={{ margin: 0 }}>{record.ticketMessage}</p>;
                  //   },
                  //   // rowExpandable: (record) => record.ticketMessage !== "qdqdqsd",
                  // }}
                  // onChange={onChange}
               />
            ) : (
               <Spin
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                     marginTop: "5%",
                  }}
                  size="large"
               />
            )}
            {/* <Table  dataSource={this.props.tickets} columns = {columns}/> */}
         </div>
      );
   }
}

export default TicketsTable;
