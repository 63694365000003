import React, { Component, useEffect, useState } from "react";
//import Table from "../utils/CustomTable";
import axios from "axios";
import {
  Checkbox,
  Col,
  Divider,
  Row,
  Skeleton,
  Spin,
  Statistic,
  Tag,
} from "antd";
import { DatePicker } from "antd";
import TransactionHistory from "../transactions/TransactionHistory";
import { Select } from "antd";
import CenteredSelction from "../utils/data/CenteredSelction";
import purchaseService from "../../services/purchaseService";
import StatisticsCard from "../utils/data/StatisticsCard";
import Text from "antd/lib/typography/Text";
import {
  ArrowUpOutlined,
  CheckCircleOutlined,
  DollarOutlined,
  DollarTwoTone,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import Axios from "axios";

//Material Table
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableItem from "../utils/purchases Table/TableItem";
import DetailsButton from "../utils/DetailsButton";

const { RangePicker } = DatePicker;
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});
const { Option } = Select;
const Purchases = (props) => {
  const [loading, setLoading] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [revenue, setRevenue] = useState(0);
  const [daily, setDaily] = useState(0);
  const [successCheck, setScucessCheck] = useState(false);
  const [failedCheck, setFailedCheck] = useState(false);
  const [successPurchases, setSuccessPurchases] = useState([]);
  const [failedPurchases, setFailedPurchases] = useState([]);
  //  const [purchasesState, setPurchasesState] = useState([]);
  const [purchasesToDisplay, setPurchasestoDisplay] = useState([]);

  //New Table consts
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [maxPage, setMaxPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pagination, setPagination] = useState(true);

  const columns = [
    { id: "name", label: "Client Name", minWidth: "100%" },

    {
      id: "delivery_method",
      label: "Delivery Method",
      minWidth: "100%",
      align: "center",
    },
    {
      id: "total_amount",
      label: "Total Amount (DT)",
      minWidth: 170,
      align: "center",
    },
    {
      id: "email",
      label: "Email ",
      minWidth: "100%",
      align: "center",
    },
    {
      id: "payment_method",
      label: "Payment Method",
      minWidth: "100%",
      align: "center",
    },
    {
      id: "payment_status",
      label: "Payment Status",
      minWidth: "100%",
      align: "center",
    },

    {
      id: "date",
      label: "Purchase Date",
      minWidth: "100%",
      align: "center",
    },
  ];

  function createData(
    _id,
    short_id,
    name,
    delivery_method,
    total_amount,
    email,
    payment_method,
    payment_status,
    provider_status,
    date,
    order_id,
    order_data
    // phoneNumber,
    // endUserAmount,
    // amount,
    // sku,
    // originalPrice,
    // provider
  ) {
    return {
      _id,
      short_id,
      name,
      delivery_method,
      total_amount,
      email,
      payment_method,
      payment_status,
      provider_status,
      date,
      order_id,
      order_data,
      // phoneNumber,
      // endUserAmount,
      // amount,
      // sku,
      // originalPrice,
      // provider,
    };
  }
  const handleChangePage = async (event, newPage) => {
    if (newPage > maxPage && pagination) {
      await getPurchases();
      setPage(newPage);

      setMaxPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const getPurchases = async () => {
    const body = {
      skip: data.length > 0 ? data[data.length - 1]._id : "",
      count: 10,
    };
    console.log(body);
    const Newdata = await Axios.post("/api/mygameplace/get-by-count", body);

    var table = data;
    Newdata.data.purchase.forEach((element) => {
      table.push(
        createData(
          element._id,
          element.short_id,
          element.user_data.first_name,
          element.user_data.delivery_method,
          element.payment_data.total_amount,
          element.user_data.email,
          element.payment_data.method,
          element.payment_data.status,
          element.provider_data.status,

          formatDate(element.creation_date),
          element.payment_data.id,
          element.order_data
        )
      );
    });

    setDataToDisplay(table);
    setData(table);
    setLoading(false);
    getPurchasesRevenue();
  };

  useEffect(() => {
    // getAllPurchases();
    getPurchases();
    console.log("mygameplace");
  }, []);

  useEffect(() => {
    getPurchasesRevenue();
  }, [dataToDisplay]);

  const getPurchasesRevenue = () => {
    var total = 0;
    dataToDisplay.forEach((purchase) => {
      if (purchase.payment_status.toUpperCase() === "SUCCESS") {
        total += parseFloat(purchase.total_amount);
      }
    });

    setRevenue(total);
  };

  const formatDate = (d) => {
    var date = new Date(d);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      "  " +
      strTime
    );
  };

  function onChangeSuccess(e) {
    setScucessCheck(e.target.checked);

    if (e.target.checked) {
      var data = [];
      data = purchasesToDisplay.filter(
        (value) =>
          value.payment_data.status == "success" &&
          value.provider_data.status == "success"
      );
      setSuccessPurchases(data);
    }
    // else {
    //   setSuccessPurchases(datatofilter);
    // }
  }

  function onChangeFailed(e) {
    setFailedCheck(e.target.checked);

    if (e.target.checked) {
      var data = [];
      data = purchasesToDisplay.filter(
        (value) =>
          value.payment_data.status == "success" &&
          value.provider_data.status == "success" &&
          value.order_data.items.length > 0 &&
          value.order_data.items[0].reference_code == ""
      );

      setFailedPurchases(data);
    }
    // else {
    //   setSuccessPurchases(datatofilter);
    // }
  }

  const handleDatePicker = async (date, dateString) => {
    var newData = [];

    if (date === null) {
      window.location.reload();
      setPagination(true);
      setDataToDisplay(data);
      getPurchasesRevenue();

      setScucessCheck(false);
    } else {
      setPagination(false);
      setScucessCheck(false);
      var startDate = new Date(dateString[0]);
      var endDate = new Date(dateString[1]);
      const body = {
        start: startDate,
        end: endDate,
      };

      const purchasesByDate = await Axios.post(
        "/api/mygameplace/get-by-date",
        body
      );
      console.log("data from picker", purchasesByDate.data.purchases[0]);

      var table = [];
      purchasesByDate.data.purchases.forEach((element) => {
        table.push(
          createData(
            element._id,
            element.short_id,
            element.user_data.first_name,
            element.user_data.delivery_method,
            element.payment_data.total_amount,
            element.user_data.email,
            element.payment_data.method,
            element.payment_data.status,
            element.provider_data.status,

            formatDate(element.creation_date),
            element.payment_data.id,
            element.order_data
          )
        );
      });

      setDataToDisplay(table);
      getPurchasesRevenue();
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="site-layout-background" style={{ minHeight: 360 }}>
      {/* <Divider orientation="center">Filters</Divider> */}
      {/* <Divider orientation="left" plain>
        Purchases
    </Divider> */}
      <div className="box-shadow">
        <div
          style={{
            width: "100%",
            minHeight: "100px",
            position: "relative",
            marginBottom: "2rem",
          }}
        >
          <div
            style={{
              marginTop: "1rem",
              marginRight: "1rem",
              position: "absolute",
              right: "0",
            }}
          >
            <div
              // className="box-shadow"
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#1890FF",
                borderRadius: "8px",
                alignItems: "center",
              }}
            >
              <div style={{ margin: "auto" }}>
                <StatisticsCard
                  subText={
                    <div>
                      <CalendarOutlined
                        style={{
                          color: "white",
                          margin: "auto",
                        }}
                      />
                      <Text
                        type="disabled"
                        style={{
                          margin: "auto",
                          color: "white",
                          marginLeft: "3px",
                        }}
                      >
                        since :{" "}
                        {purchasesToDisplay[purchasesToDisplay.length - 1] !=
                        undefined ? (
                          formatDate(
                            purchasesToDisplay[purchasesToDisplay.length - 1]
                              .creation_date
                          )
                        ) : (
                          <Spin indicator={antIcon} />
                        )}
                      </Text>
                    </div>
                  }
                  title="Total Income"
                  value={revenue.toFixed(3)}
                  suffix={props.client ? "LD" : "TND"}
                />
              </div>

              <div>
                <DollarOutlined
                  // className="blue-gradiant"
                  style={{
                    fontSize: "28px",
                    padding: "1rem",
                    color: "#87D068",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: "0px",
              paddingLeft: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  marginLeft: "7px",
                  marginRight: "7px",
                  fontWeight: "bold",
                }}
              >
                Pick By Date
              </span>
              <RangePicker onChange={handleDatePicker} />
              <span
                style={{
                  marginLeft: "7px",
                  marginRight: "7px",
                  fontWeight: "bold",
                }}
              >
                <Checkbox onChange={onChangeSuccess} checked={successCheck}>
                  Select success
                </Checkbox>
              </span>
              <span
                style={{
                  marginLeft: "7px",
                  marginRight: "7px",
                  fontWeight: "bold",
                }}
              >
                <Checkbox onChange={onChangeFailed} checked={failedCheck}>
                  Select Failed
                </Checkbox>
              </span>
              <span
                style={{
                  marginLeft: "1rem",
                  marginRight: "7px",
                  fontWeight: "bold",
                }}
              >
                {/* <div>Display Success</div> */}
              </span>
            </div>
          </div>
        </div>
        {!loading ? (
          <Row>
            {/* <Col flex="12" className="grid-games">
              <Table
                purchases={
                  successCheck
                    ? successPurchases
                    : failedCheck
                    ? failedPurchases
                    : purchasesToDisplay
                }
              /> */}

            {/* <TransactionHistory /> */}
            {/* </Col> */}
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontWeight: "bold",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Provider Status
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataToDisplay
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <TableItem
                                    table="mygameplace"
                                    value={value}
                                    color="red"
                                  />
                                </TableCell>
                              );
                            })}
                            <TableCell>
                              {row.order_data.items.length == 0 ? (
                                <Tag color="#f50" key="error">
                                  ERROR
                                </Tag>
                              ) : row.order_data.items[0].reference_code ==
                                "N/A" ? (
                                <Tag color="#f50" key="error">
                                  ERROR
                                </Tag>
                              ) : (
                                <Tag color="#87d068" key="success">
                                  SUCCESS
                                </Tag>
                              )}
                            </TableCell>
                            <TableCell>
                              {/* <ResendButton id={row._id} /> */}
                              <DetailsButton orderId={row.order_id} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                //rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={10 + dataToDisplay.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Row>
        ) : (
          <Spin
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            size="large"
          />
        )}
      </div>
    </div>
  );
};

export default Purchases;
