import React, { useEffect, useState, useContext } from "react";
import { Col, Divider, Row, Skeleton, Spin, Statistic } from "antd";
import axios from "axios";
import SobflousTable from "../utils/SobflousTable";
import TransactionSobflousHistory from "../transactions/TransactionSobflousHistory";
import CenteredSelction from "../utils/data/CenteredSelction";
import { Select } from "antd";
import PurchaseClient from "./PurchaseClient";
import UmbrellaPurchases from "./UmbrellaPurchases";

import {
  LoadingOutlined,
  PaperClipOutlined,
  DownloadOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import UserContext from "../../context/UserContext";
import OrangePurchases from "./OrangePurchases";
import TopnetPurchase from "./TopnetPurchases";
const { Option } = Select;
const PurchaseLanding = (props) => {
  const [loading, setLoading] = useState(true);

  const [purchases, setPurchases] = useState([]);
  const userContext = useContext(UserContext);
  const getAllPurchases = () => {
    if (props.match.params.pagename === "orange") {
      //  setLoading(true);

      setLoading(false);
    } else if (props.match.params.pagename === "gamecode") {
      setLoading(false);
      // axios.get("/api/purchase/get/lybianaPurchases").then((res) => {
      //   setPurchases(res.data);
      //   // setPurchasestoDisplay(res.data);
      //   setLoading(false);
      // });
    } else if (props.match.params.pagename === "umbrella") {
      const body = { market: props.match.params.pagename };
      setLoading(false);
      console.log("umbrella");
      // axios.post("/api/purchase/getbymarket", body).then((res) => {
      //   setPurchases(res.data);
      //   console.log("umbrella", res.data);
      //   // setPurchasestoDisplay(res.data);
      //   setLoading(false);
      // });
    } else if (props.match.params.pagename === "topnet") {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllPurchases();
    console.log("refresh");
  }, [props.match.params.pagename]);
  const antIcon = <LoadingOutlined style={{ fontSize: 70 }} spin />;
  return (
    <div className="site-layout-background" style={{ minHeight: 360 }}>
      {!loading ? (
        props.match.params.pagename == "orange" ? (
          <OrangePurchases
            purchases={purchases}
            retailer={props.match.params.pagename}
            user={userContext.userData.user}
          />
        ) : props.match.params.pagename == "gamecode" ? (
          <PurchaseClient
            purchases={purchases}
            retailer={props.match.params.pagename}
            user={userContext.userData.user}
          />
        ) : props.match.params.pagename == "umbrella" ? (
          <UmbrellaPurchases
            purchases={purchases}
            retailer={props.match.params.pagename}
            user={userContext.userData.user}
          />
        ) : props.match.params.pagename == "topnet" ? (
          <TopnetPurchase
            retailer={props.match.params.pagename}
            user={userContext.userData.user}
          />
        ) : (
          <div>404</div>
        )
      ) : (
        <div
          style={{
            top: "50%",
            position: "absolute",
            left: "50%",
          }}
        >
          <Spin indicator={antIcon} />
          <div> Data fetching</div>
        </div>
      )}
      {/* {!loading ? (
        <div>
          <div className="header-secondary">Sobflous Purchases List</div>
          <Divider orientation="center">Stats</Divider>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <Skeleton loading={loading}>
              <Statistic
                title="Revenue"
                value={getPurchasesRevenue()}
                // precision={2}
                valueStyle={{
                  color: "#3f8600",
                  padding: "2%",
                  fontWeight: "bold",
                }}
                suffix="TND"
              />
            </Skeleton>
          </div>
        </div>
      ) : null}

      {!loading ? (
        <Row gutter={16}>
          <Col flex="12" className="grid-games">
            <SobflousTable purchases={purchases} />
            <TransactionSobflousHistory />
          </Col>
        </Row>
      ) : (
        <Spin
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          size="large"
        />
      )} */}
    </div>
  );
};

export default PurchaseLanding;
